import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataGrid';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

// Define the columns for the MUI DataGrid
const columns = [
  { field: 'propertyName', headerName: 'Property Name', flex: 1 },
  { field: 'propertyEmail', headerName: 'Property Email', flex: 1 },
  { field: 'phone', headerName: 'Phone', flex: 1 },
  { field: 'state', headerName: 'State', flex: 1 },
  { field: 'unitNumber', headerName: 'Unit Number', flex: 1 },
  { field: 'activeBintags', headerName: 'Active Bintags', flex: 1 },
];

const UnitsPage = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const communitiesRef = collection(db, 'communities');
      const communitiesSnapshot = await getDocs(communitiesRef);
      const communitiesList = await Promise.all(communitiesSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const bintagsRef = collection(db, `communities/${doc.id}/bintags`);
        const bintagsSnapshot = await getDocs(bintagsRef);
        const activeBintagsCount = bintagsSnapshot.docs.filter(bintagDoc => bintagDoc.data().active).length;

        return {
          id: doc.id,
          propertyName: data.propertyName || '',
          propertyEmail: data.propertyEmail || '',
          phone: data.phone || '',
          state: data.state || '',
          unitNumber: data.unitNumber || '',
          activeBintags: activeBintagsCount,
        };
      }));

      setRows(communitiesList);
    };

    fetchData();
  }, []);

  return (
    <div>
      <DataTable rows={rows} columns={columns} dateFilterOptions={[]} />
    </div>
  );
};

export default UnitsPage;
