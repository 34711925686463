import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import DataTable from '../../components/DataGrid';
import Modal from '../../components/Modal';
import { format, differenceInMinutes } from 'date-fns';
import ImageIcon from '@mui/icons-material/Image';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { green, red, orange } from '@mui/material/colors';

const columns = [
  { field: 'employee', headerName: 'Employee', flex: 1 },
  { field: 'date', headerName: 'Date', flex: 1 },
  { field: 'avgDuration', headerName: 'Avg Duration', flex: 1 },
  {
    field: 'compactorPhotos',
    headerName: 'Compactor Photos',
    flex: 1,
    renderCell: (params) => {
      const status = params.value;
      const color = status === 'Completed' ? green[500] : (status === 'Before Only' ? orange[500] : red[500]);
      return <Chip label={status} style={{ backgroundColor: color, color: 'white' }} />;
    }
  },
  { field: 'binTagScannedPercent', headerName: 'Bin Tag %', flex: 1 },
  {
    field: 'violations',
    headerName: 'Violations',
    flex: 1,
    renderCell: (params) => {
      const status = params.value >= params.row.expectedViolations ? green[500] : red[500];
      return <Chip label={params.value} style={{ backgroundColor: status, color: 'white' }} />;
    }
  }
];

const dateFilterOptions = [
  { label: "Date", value: "date" }
];

const DailyReportsPage = () => {
  const [rows, setRows] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const clockInOutsSnapshot = await getDocs(collection(db, 'clockInOuts'));
      const usersSnapshot = await getDocs(collection(db, 'team'));
      const bintagScansSnapshot = await getDocs(collection(db, 'bintagscans'));
      const violationsSnapshot = await getDocs(collection(db, 'violations'));
      const communitiesSnapshot = await getDocs(collection(db, 'communities'));

      const userMap = usersSnapshot.docs.reduce((acc, doc) => {
        const userData = doc.data();
        acc[doc.id] = `${userData.firstName} ${userData.lastName}`;
        return acc;
      }, {});

      const communityMap = communitiesSnapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        acc[doc.id] = {
          name: data.propertyName,
          unitNumber: data.unitNumber,
          activeBintags: 0
        };
        return acc;
      }, {});

      for (const doc of communitiesSnapshot.docs) {
        const communityId = doc.id;
        const bintagsSnapshot = await getDocs(collection(db, `communities/${communityId}/bintags`));
        communityMap[communityId].activeBintags = bintagsSnapshot.docs.filter(bintagDoc => bintagDoc.data().active).length;
      }

      const bintagScanMap = bintagScansSnapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        if (!acc[data.logID]) {
          acc[data.logID] = new Set();
        }
        acc[data.logID].add(data.scanResult);
        return acc;
      }, {});

      const violationsMap = violationsSnapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        if (!acc[data.EmployeeID]) {
          acc[data.EmployeeID] = [];
        }
        acc[data.EmployeeID].push(data);
        return acc;
      }, {});

      const allData = clockInOutsSnapshot.docs.map(doc => {
        const docData = doc.data();
        const logID = doc.id;
        const employeeName = userMap[docData.UserID] || '';
        const propertyName = docData['Property Name'] || '';
        const clockin = docData.Clockin.toDate();
        const clockout = docData.Clockout ? docData.Clockout.toDate() : null;

        let avgDuration = 'N/A';
        if (clockin && clockout) {
          avgDuration = differenceInMinutes(clockout, clockin);
        }

        let compactorPhotos = 'None';
        if (docData.beforeTrashCompactor && docData.afterTrashCompactor) {
          compactorPhotos = 'Completed';
        } else if (docData.beforeTrashCompactor) {
          compactorPhotos = 'Before Only';
        }

        const scannedBintags = bintagScanMap[logID] ? bintagScanMap[logID].size : 0;
        const totalBintags = communityMap[propertyName]?.activeBintags || 0;
        const binTagScannedPercent = totalBintags ? (scannedBintags / totalBintags) * 100 : 0;

        const totalUnits = communityMap[propertyName]?.unitNumber || 0;
        const expectedViolations = Math.ceil(totalUnits * 0.01);
        const actualViolations = violationsMap[docData.UserID]?.filter(violation => violation.logId === logID).length || 0;

        return {
          id: logID,
          employee: employeeName,
          date: format(clockin, 'MM/dd/yyyy'),
          avgDuration: avgDuration !== 'N/A' ? `${avgDuration} mins` : avgDuration,
          compactorPhotos,
          binTagScannedPercent: `${binTagScannedPercent.toFixed(2)}%`,
          violations: actualViolations,
          expectedViolations
        };
      });

      setRows(allData);
    };

    fetchData();
  }, []);

  const handleShowImage = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setShowImageModal(true);
  };

  return (
    <div>
      <DataTable
        rows={rows}
        columns={columns}
        dateFilterOptions={dateFilterOptions}
      />
      <Modal isOpen={showImageModal} onClose={() => setShowImageModal(false)}>
        <img src={currentImageUrl} alt="Violation" style={{ width: '100%' }} />
      </Modal>
    </div>
  );
};

export default DailyReportsPage;
