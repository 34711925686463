import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, setDoc, query, where } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Modal from '../components/Modal';
import AddressAutocomplete from '../components/AddressAutocomplete';
import "./Community.css";

const Community = () => {
  const navigate = useNavigate();
  const [savedProperties, setSavedProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [propertyName, setPropertyName] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  const [propertyEmail, setPropertyEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [apartmentType, setApartmentType] = useState('');
  const [propertyMap, setPropertyMap] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSavedProperties = async () => {
      const savedPropertySnapshot = await getDocs(collection(db, "communities"));
      const savedPropertyArray = savedPropertySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSavedProperties(savedPropertyArray);
    };

    fetchSavedProperties();
  }, []);

  const handlePropertyClick = (propertyId) => {
    navigate(`/admin/properties/${propertyId}`);
  };

  const handleAddProperty = () => {
    setCurrentProperty('');
    setPropertyName('');
    setUnitNumber('');
    setPropertyEmail('');
    setPhone('');
    setState('');
    setAddress('');
    setZipCode('');
    setApartmentType('');
    setPropertyMap(null);
    setLatitude(null);
    setLongitude(null);
    setError('');
    setModalOpen(true);
  };

  const validateInputs = async () => {
    const urlSafePropertyName = propertyName.trim().toLowerCase().replace(/\s+/g, '-');

    if (savedProperties.some(property => property.id === urlSafePropertyName)) {
      return "Property name already exists.";
    }

    const emailQuery = query(collection(db, "communities"), where("propertyEmail", "==", propertyEmail));
    const emailSnapshot = await getDocs(emailQuery);
    if (!emailSnapshot.empty) {
      return "Email is already in use.";
    }

    return null;
  };

  const handleSaveProperty = async (event) => {
    event.preventDefault();
    const validationError = await validateInputs();
    if (validationError) {
      setError(validationError);
      return;
    }

    let downloadURL = '';
    if (propertyMap) {
      const storageRef = ref(storage, `propertyMaps/${propertyName.trim().toLowerCase().replace(/\s+/g, '-')}-${propertyMap.name}`);
      await uploadBytes(storageRef, propertyMap);
      downloadURL = await getDownloadURL(storageRef);
    }

    const temporaryPassword = Math.random().toString(36).slice(-8);
    const urlSafePropertyName = propertyName.trim().toLowerCase().replace(/\s+/g, '-').replace(/-+$/, '');

    const docRef = doc(db, "communities", urlSafePropertyName);
    const propertyData = {
      propertyName: propertyName.trim(),
      unitNumber,
      propertyEmail,
      phone,
      state,
      address,
      zipCode,
      apartmentType,
      propertyMap: downloadURL,
      latitude,
      longitude,
      temporaryPassword
    };

    await setDoc(docRef, propertyData);
    setSavedProperties([...savedProperties, { id: urlSafePropertyName, ...propertyData }]);
    setModalOpen(false);
    navigate(`/admin/properties/${urlSafePropertyName}`);
  };

  return (
    <div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search for properties..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <button onClick={handleAddProperty} className="save-button">Add Property</button>
      </div>
      <div className="community-container">
        {savedProperties
          .filter(property => property.propertyName.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((property, index) => (
            <div key={index} onClick={() => handlePropertyClick(property.id)} className="property-card">
              {property.propertyName}
            </div>
          ))}
      </div>
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <form onSubmit={handleSaveProperty} className="modal-form">
            <label>
              Property Name:
              <input type="text" value={propertyName} onChange={(e) => setPropertyName(e.target.value)} required />
            </label>
            <label>
              Unit #:
              <input type="number" value={unitNumber} onChange={(e) => setUnitNumber(e.target.value)} required />
            </label>
            <label>
              Property Email:
              <input type="email" value={propertyEmail} onChange={(e) => setPropertyEmail(e.target.value)} required />
            </label>
            <label>
              Phone:
              <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} required />
            </label>
            <label>
              Address:
              <AddressAutocomplete address={address} setAddress={setAddress} setState={setState} setZipCode={setZipCode} setLatitude={setLatitude} setLongitude={setLongitude} />
            </label>
            <label>
              State:
              <input type="text" value={state} onChange={(e) => setState(e.target.value)} required />
            </label>
            <label>
              Zip Code:
              <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} required />
            </label>
            <label>
              Apartment Type:
              <select value={apartmentType} onChange={(e) => setApartmentType(e.target.value)} required>
                <option value="">Select Type</option>
                <option value="Mid-Rise">Mid-Rise</option>
                <option value="Garden">Garden</option>
                <option value="High-Rise">Walk-Ups</option>
              </select>
            </label>
            <label>
              Property map image:
              <input type="file" onChange={(e) => setPropertyMap(e.target.files[0])} />
            </label>
            {error && <p className="error">{error}</p>}
            <button type="submit">Save</button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Community;
