import React, { useState, useEffect } from 'react';
import TrashCompactorPhotos from './TrashCompactorPhotos';
import ViolationsForm from './Violations';
import BinTagScanner from './BinTagScanner'; // Updated import
import Modal from '../../../components/Modal';
import { db, storage } from '../../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, doc, getDoc, getDocs, query, where, updateDoc } from 'firebase/firestore';
import './EmployeeToolkit.css';
import compactorIcon from '../../../assets/compactor.svg';
import violationsIcon from '../../../assets/violations.svg';
import binTagIcon from '../../../assets/bintag.svg';

const EmployeeToolkit = ({ property, employeeId, activeClockInId }) => {
    const [activeTool, setActiveTool] = useState(null);
    const [currentPhotoStage, setCurrentPhotoStage] = useState('before');
    const [compactorPhotoCount, setCompactorPhotoCount] = useState(0);
    const [isCompactorCompleted, setIsCompactorCompleted] = useState(false);
    const [binTags, setBinTags] = useState([]);
    const [scannedBinTags, setScannedBinTags] = useState(0);
    const [violationsCount, setViolationsCount] = useState(0);
    const [propertyName, setPropertyName] = useState('');

    useEffect(() => {
        const fetchClockInData = async () => {
            if (activeClockInId) {
                const clockInRef = doc(db, 'clockInOuts', activeClockInId);
                const docSnap = await getDoc(clockInRef);
                if (docSnap.exists()) {
                    setPropertyName(docSnap.data()['Property Name']);
                    const beforePhoto = docSnap.data().beforeTrashCompactor;
                    const afterPhoto = docSnap.data().afterTrashCompactor;
                    let count = 0;
                    if (beforePhoto) count += 1;
                    if (afterPhoto) count += 1;
                    setCompactorPhotoCount(count);
                    setIsCompactorCompleted(count === 2);
                }
            }
        };

        fetchClockInData();
    }, [activeClockInId]);

    useEffect(() => {
        const fetchBinTags = async () => {
            if (propertyName) {
                const binTagsSnapshot = await getDocs(collection(db, 'communities', propertyName, 'bintags'));
                const binTagsData = binTagsSnapshot.docs.map(doc => doc.data());
                setBinTags(binTagsData);
            }
        };

        fetchBinTags();
    }, [propertyName]);

    useEffect(() => {
        const fetchScannedBinTags = async () => {
            if (activeClockInId) {
                const q = query(collection(db, 'bintagscans'), where('logID', '==', activeClockInId));
                const scannedBinTagsSnapshot = await getDocs(q);
                const uniqueScannedTags = new Set(scannedBinTagsSnapshot.docs.map(doc => doc.data().scanResult));
                setScannedBinTags(uniqueScannedTags.size);
            }
        };

        fetchScannedBinTags();
    }, [activeClockInId]);

    useEffect(() => {
        const fetchViolationsCount = async () => {
            if (activeClockInId) {
                const q = query(collection(db, 'violations'), where('logId', '==', activeClockInId));
                const violationsSnapshot = await getDocs(q);
                setViolationsCount(violationsSnapshot.size);
            }
        };

        fetchViolationsCount();
    }, [activeClockInId]);

    const savePhoto = async (photoStage, photo) => {
        const imageBlob = await fetch(photo).then(res => res.blob());
        const imageName = `${employeeId}_${photoStage}_trashCompactor_${new Date().toISOString()}.png`;
        const imageRef = ref(storage, `compactorImages/${imageName}`);

        try {
            await uploadBytes(imageRef, imageBlob);
            const imageUrl = await getDownloadURL(imageRef);
            const clockInRef = doc(db, 'clockInOuts', activeClockInId);
            const fieldToUpdate = photoStage === 'before' ? 'beforeTrashCompactor' : 'afterTrashCompactor';

            await updateDoc(clockInRef, {
                [fieldToUpdate]: {
                    url: imageUrl,
                    timestamp: new Date()
                }
            });

            // Update photo count
            const newPhotoCount = photoStage === 'before' ? compactorPhotoCount + 1 : compactorPhotoCount;
            setCompactorPhotoCount(newPhotoCount);
            setIsCompactorCompleted(newPhotoCount === 2);

            if (photoStage === 'before') {
                setCurrentPhotoStage('after');
            }
        } catch (error) {
            console.error('Failed to upload image or update Firestore:', error);
        }
    };

    const handleOpenTool = (tool) => {
        if (tool === 'trashCompactorPhotos' && isCompactorCompleted) {
            return;
        }
        if (tool === 'binTagScan' && isBinTagScanCompleted) {
            return;
        }
        setActiveTool(tool);
    };

    const handleCloseTool = () => {
        if (activeTool === 'trashCompactorPhotos') {
            setCurrentPhotoStage(currentPhotoStage === 'before' ? 'after' : 'before');
        }
        setActiveTool(null);
    };

    const handleScanComplete = () => {
        setScannedBinTags(prev => prev + 1);
        handleCloseTool();
    };

    const isBinTagScanCompleted = scannedBinTags === binTags.length;

    return (
        <div className="employee-toolkit">
            <div className="tool-grid">
                <div
                    className={`tool ${isCompactorCompleted ? 'completed' : ''}`}
                    onClick={() => handleOpenTool('trashCompactorPhotos')}
                >
                    <img src={compactorIcon} alt="Trash Compactor Photos" />
                    <p>{compactorPhotoCount}/2</p>
                </div>
                <div className="tool" onClick={() => handleOpenTool('violationsForm')}>
                    <img src={violationsIcon} alt="Report Violation" />
                    <p>{violationsCount}</p>
                </div>
                <div
                    className={`tool ${isBinTagScanCompleted ? 'completed' : ''}`}
                    onClick={() => handleOpenTool('binTagScan')}
                >
                    <img src={binTagIcon} alt="Bin Tags" />
                    <p>{scannedBinTags}/{binTags.length}</p>
                </div>
            </div>
            {activeTool === 'trashCompactorPhotos' && (
                <Modal isOpen={true} onClose={handleCloseTool}>
                    <TrashCompactorPhotos
                        userId={employeeId}
                        currentPhotoStage={currentPhotoStage}
                        savePhoto={savePhoto}
                        onClose={handleCloseTool}
                        activeClockInId={activeClockInId}
                        setIsCompactorCompleted={setIsCompactorCompleted}
                    />
                </Modal>
            )}
            {activeTool === 'violationsForm' && (
                <Modal isOpen={true} onClose={handleCloseTool}>
                    <ViolationsForm 
                        property={propertyName} // Ensure propertyName is passed correctly
                        employeeId={employeeId} 
                        activeClockInId={activeClockInId} 
                        onClose={handleCloseTool} 
                    />
                </Modal>
            )}
            {activeTool === 'binTagScan' && (
                <Modal isOpen={true} onClose={handleCloseTool}>
                    <BinTagScanner
                        activeClockInId={activeClockInId}
                        onClose={handleCloseTool}
                    />
                </Modal>
            )}
        </div>
    );
};

export default EmployeeToolkit;
