import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import '../pages/EmployeeDashboard.css';
import mobileIcon from '../../../assets/phone.svg';

const SpecialistGrid = () => {
    const auth = getAuth();
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [specialists, setSpecialists] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userDoc = await getDoc(doc(db, 'team', currentUser.uid));
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setUserData(data);
                    if (data.tier === 'District Manager') {
                        setSpecialists(data.assignedSpecialists || []);
                    }
                }
            }
        });
    }, [auth]);

    if (!user || !userData || userData.tier !== 'District Manager') {
        return null; // Do not render the component if the user is not a District Manager
    }

    return (
        <div className="specialist-grid">
            {specialists.map((specialist) => (
                <div key={specialist.id} className="specialist-card" onClick={() => navigate(`/profile/${specialist.id}`)}>
                    <img src={specialist.profilePic} alt={`${specialist.firstName} ${specialist.lastName}`} className="specialist-pic" />
                    <div className="specialist-info">
                        <p>{`${specialist.firstName} ${specialist.lastName}`}</p>
                        <a href={`tel:${specialist.phone}`}>
                            <img src={mobileIcon} alt="mobile icon" className="mobile-icon" />
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SpecialistGrid;
