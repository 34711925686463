// Dashboard.jsx

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import SideNav from '../components/nav/header';
import MobileNav from '../components/nav/MobileNav';
import DashboardHeader from '../components/nav/DashboardHeader';
import './Dashboard.css';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation(); 
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let title;

    if (/^\/properties\/.+/.test(location.pathname)) {
        title = 'Property Report'; // Set title for any specific property page
    } else if (/^\/team\/.+/.test(location.pathname)) {
        title = 'Employee Profile'; // Set title for any specific employee page
    } else {
        switch (location.pathname) {
            case '/admin/':
                title = 'Home';
                break;
            case '/admin/clock-in-outs':
                title = 'Clock In Report';
                break;
            case '/admin/services':
                title = 'Service Report';
                break;
            case '/admin/units':
                title = 'Unit Report';
                break;
            case '/admin/violations':
                title = 'Violation Report';
                break;
            case '/admin/data-input':
                title = 'Live Tracking';
                break;
            case '/admin/communities':
                title = 'Communities';
                break;
            case '/admin/team':
                title = 'Team';
                break;
            case '/admin/daily-report':
                title = 'Daily Report';
                break;
            case '/admin/settings':
                title = 'Settings';
                break;
            default:
                title = 'Dashboard'; // Default title for other cases
        }
    }

     return (
        <div className={`dashboard ${isMobile ? 'dashboard-mobile' : ''}`}>
            <SideNav />
            <div className="dashboard-content">
                <DashboardHeader title={title} />
                <Outlet />
            </div>
            {isMobile && <MobileNav />}
        </div>
    );
}

export default Dashboard;