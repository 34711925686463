import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import './AllBinTags.css';

const AllBinTags = () => {
    const { propertyName } = useParams();
    const [binTags, setBinTags] = useState([]);
    const [selectedBinTags, setSelectedBinTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortColumn, setSortColumn] = useState('createdAt');

    useEffect(() => {
        const fetchBinTags = async () => {
            const binTagsCollection = collection(db, 'communities', propertyName, 'bintags');
            const binTagsSnapshot = await getDocs(binTagsCollection);
            setBinTags(binTagsSnapshot.docs.map(docRef => ({ id: docRef.id, ...docRef.data() })));
        };

        fetchBinTags();
    }, [propertyName]);

    const handleSelectBinTag = (binTagId) => {
        setSelectedBinTags((prevSelected) =>
            prevSelected.includes(binTagId) ? prevSelected.filter(id => id !== binTagId) : [...prevSelected, binTagId]
        );
    };

    const handleDeleteSelected = async () => {
        for (const binTagId of selectedBinTags) {
            const binTagRef = doc(db, 'communities', propertyName, 'bintags', binTagId);
            await deleteDoc(binTagRef);
        }
        setBinTags(binTags.filter(binTag => !selectedBinTags.includes(binTag.id)));
        setSelectedBinTags([]);
    };

    const handleActivateSelected = async () => {
        for (const binTagId of selectedBinTags) {
            const binTagRef = doc(db, 'communities', propertyName, 'bintags', binTagId);
            await updateDoc(binTagRef, { active: true });
        }
        setBinTags(binTags.map(binTag => (selectedBinTags.includes(binTag.id) ? { ...binTag, active: true } : binTag)));
        setSelectedBinTags([]);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = (column) => {
        const sortedBinTags = [...binTags].sort((a, b) => {
            if (column === 'createdAt') {
                if (sortOrder === 'asc') {
                    return new Date(a.createdAt.seconds * 1000) - new Date(b.createdAt.seconds * 1000);
                } else {
                    return new Date(b.createdAt.seconds * 1000) - new Date(a.createdAt.seconds * 1000);
                }
            } else if (column === 'status') {
                if (sortOrder === 'asc') {
                    return a.active === b.active ? 0 : a.active ? 1 : -1;
                } else {
                    return a.active === b.active ? 0 : a.active ? -1 : 1;
                }
            }
            return 0;
        });

        setBinTags(sortedBinTags);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setSortColumn(column);
    };

    const filteredBinTags = binTags.filter(binTag =>
        binTag.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="all-bin-tags-container">
            <div className="bt-header">
                <h2>All Bin Tags</h2>
                <input type="text" placeholder="Search bin tags..." value={searchTerm} onChange={handleSearch} />
            </div>
            <table className="bin-tags-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>ID</th>
                        <th>Building</th>
                        <th onClick={() => handleSort('createdAt')} style={{ cursor: 'pointer' }}>Created At</th>
                        <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>Status</th>
                        <th>QR Code URL</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredBinTags.map((binTag) => (
                        <tr key={binTag.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedBinTags.includes(binTag.id)}
                                    onChange={() => handleSelectBinTag(binTag.id)}
                                />
                            </td>
                            <td>{binTag.name}</td>
                            <td>{binTag.id}</td>
                            <td>{binTag.building}</td>
                            <td>{new Date(binTag.createdAt.seconds * 1000).toLocaleDateString()}</td>
                            <td>{binTag.active ? 'Active' : 'Inactive'}</td>
                            <td>{binTag.qrCodeURL}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedBinTags.length > 0 && (
                <div className="batch-actions">
                    <button onClick={handleDeleteSelected} className="delete-button">Delete Selected</button>
                    <button onClick={handleActivateSelected} className="save-button">Activate Selected</button>
                </div>
            )}
        </div>
    );
};

export default AllBinTags;
