import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, addDoc, collection, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import Loader from '../components/Loader'; // Import the Loader component

const BintagRedirect = () => {
  const { propertyName, bintagId } = useParams();
  const navigate = useNavigate();
  const hasLogged = useRef(false); // This ref will ensure we only log once

  useEffect(() => {
    const logScanEvent = async () => {
      if (hasLogged.current) {
        return; // If already logged, do nothing
      }
      hasLogged.current = true; // Set the flag to true
      console.log(`Redirecting and possibly logging for property: ${propertyName}, bintag: ${bintagId}`);
      
      try {
        const bintagRef = doc(db, 'communities', propertyName, 'bintags', bintagId);
        const bintagDoc = await getDoc(bintagRef);
    
        if (bintagDoc.exists()) {
          console.log(`Bin tag ${bintagId} found.`);

          // Check if the bintag is active
          const isActive = bintagDoc.data().active;
          if (!isActive) {
            console.log(`Bin tag ${bintagId} is not active.`);
            return;
          }
    
          // Check for active clock-ins
          const clockInsCollection = collection(db, 'clockInOuts');
          const clockInQuery = query(clockInsCollection, 
            where('Property Name', '==', propertyName), 
            where('Clockout', '==', null));
          const clockInSnapshot = await getDocs(clockInQuery);
    
          if (!clockInSnapshot.empty) {
            console.log(`Active clock-in found for property ${propertyName}.`);
    
            const activeClockIn = clockInSnapshot.docs[0];
            const logID = activeClockIn.id;
            const scanResult = bintagId;
            const timestamp = serverTimestamp();

            // Check if this bin tag was already scanned during this clock-in session
            const scansCollection = collection(db, 'bintagscans');
            const scanQuery = query(scansCollection, 
              where('logID', '==', logID), 
              where('scanResult', '==', scanResult));
            const scanSnapshot = await getDocs(scanQuery);

            if (scanSnapshot.empty) {
              const scanLog = {
                logID,
                scanResult,
                timestamp,
              };

              await addDoc(collection(db, 'bintagscans'), scanLog);
              console.log(`Logged scan for bin tag ${bintagId} with clock-in ID ${logID}`);
            } else {
              console.log(`Bin tag ${bintagId} already scanned during this session.`);
            }
          } else {
            console.log('No active clock-in found for this property.');
          }
        } else {
          console.error(`No document found for bin tag: ${bintagId}`);
        }
      } catch (error) {
        console.error('Error during scan logging:', error);
      } finally {
        console.log(`Redirecting to /community/${propertyName}`);
        navigate(`/community/${propertyName}`);
      }
    };

    logScanEvent();
  }, [propertyName, bintagId, navigate]);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default BintagRedirect;
