import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './HeaderPublic.css';
import logo from '../../assets/long-logo.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const closeMenu = (e) => {
      if (isMenuOpen && !e.target.closest('.wastefree-nav') && !e.target.closest('.wastefree-menu-icon')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, [isMenuOpen]);

  return (
    <header className="wastefree-header-community">
      <div className="wastefree-header-container">
        <div className="wastefree-logo">
          <Link to="/"><img src={logo} alt="ClarityUI Logo" /></Link>
        </div>
        <div className="wastefree-menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </div>
        <nav className={`wastefree-nav ${isMenuOpen ? 'open' : ''}`}>
          <ul className="wastefree-nav-list">
            <li className="wastefree-nav-item">
              <a href="https://www.wastefree.com/pricing/packages" onClick={() => setIsMenuOpen(false)}>Services</a>
            </li>
            <li className="wastefree-nav-item">
              <a href="https://www.wastefree.com/how-it-works/residents" onClick={() => setIsMenuOpen(false)}>Residents</a>
            </li>
            <li className="wastefree-nav-item">
              <a href="https://www.wastefree.com/blog" onClick={() => setIsMenuOpen(false)}>Blog</a>
            </li>
            <li className="wastefree-nav-item">
              <a href="#contact" className="wastefree-nav-button" onClick={() => setIsMenuOpen(false)}>Contact Us</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
