import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { Avatar, Chip, IconButton, Modal, Box, Typography, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import defaultProfilePic from '../../assets/default.png'; // Ensure the correct path

const AssignTeamMember = ({ employee }) => {
    const { employeeId } = useParams();
    const [collectionSpecialists, setCollectionSpecialists] = useState([]);
    const [assignedSpecialists, setAssignedSpecialists] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchCollectionSpecialists = async () => {
            const teamSnapshot = await getDocs(collection(db, 'team'));
            const specialists = teamSnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(member => member.tier === 'Collection Specialist' && !member.reportingManager);
            setCollectionSpecialists(specialists);
        };

        const fetchAssignedSpecialists = async () => {
            const docRef = doc(db, "team", employeeId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setAssignedSpecialists(docSnap.data().assignedSpecialists || []);
            }
        };

        fetchCollectionSpecialists();
        fetchAssignedSpecialists();
    }, [employeeId]);

    const handleAddSpecialist = async (specialist) => {
        const updatedSpecialists = [...assignedSpecialists, specialist];
        setAssignedSpecialists(updatedSpecialists);

        const docRef = doc(db, "team", employeeId);
        await updateDoc(docRef, { assignedSpecialists: updatedSpecialists });

        const specialistRef = doc(db, "team", specialist.id);
        await updateDoc(specialistRef, { reportingManager: `${employee.firstName} ${employee.lastName}` });

        setModalOpen(false);
    };

    const handleRemoveSpecialist = async (specialistId) => {
        const updatedSpecialists = assignedSpecialists.filter(specialist => specialist.id !== specialistId);
        setAssignedSpecialists(updatedSpecialists);

        const docRef = doc(db, "team", employeeId);
        await updateDoc(docRef, { assignedSpecialists: updatedSpecialists });

        const specialistRef = doc(db, "team", specialistId);
        await updateDoc(specialistRef, { reportingManager: "" });
    };

    return (
        <div className="assign-reporting-manager">
            <Typography variant="h6">Add Team Members</Typography>
            <div className="specialists-list">
                {assignedSpecialists.map(specialist => (
                    <Chip
                        key={specialist.id}
                        avatar={<Avatar src={specialist.profilePic || defaultProfilePic} />}
                        label={`${specialist.firstName} ${specialist.lastName}`}
                        onDelete={() => handleRemoveSpecialist(specialist.id)}
                    />
                ))}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
            />
            <div className="search-results">
                {collectionSpecialists.filter(specialist =>
                    (`${specialist.firstName} ${specialist.lastName}`).toLowerCase().includes(searchTerm.toLowerCase())
                ).map(specialist => (
                    <Chip
                        key={specialist.id}
                        avatar={<Avatar src={specialist.profilePic || defaultProfilePic} />}
                        label={`${specialist.firstName} ${specialist.lastName}`}
                        onClick={() => handleAddSpecialist(specialist)}
                    />
                ))}
            </div>
        </div>
    );
};

export default AssignTeamMember;
