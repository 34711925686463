import React, { useState } from 'react';
import './AnimatedTabs.css';

const AnimatedTabs = ({ onTabChange }) => {
  const [activeTab, setActiveTab] = useState('Pending');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    onTabChange(tab);
  };

  return (
    <div className="animated-tabs">
      <div
        className={`tab ${activeTab === 'Pending' ? 'active' : ''}`}
        onClick={() => handleTabClick('Pending')}
      >
        Pending
      </div>
      <div
        className={`tab ${activeTab === 'Serviced' ? 'active' : ''}`}
        onClick={() => handleTabClick('Serviced')}
      >
        Serviced
      </div>
      <div className={`animated-tab-indicator ${activeTab === 'Serviced' ? 'indicator-serviced' : ''}`} />
    </div>
  );
};

export default AnimatedTabs;
