import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, limit, updateDoc, doc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import DataTable from '../../components/DataGrid';
import Modal from '../../components/Modal';
import { format, parse } from 'date-fns';
import './reports.css';
import ImageIcon from '@mui/icons-material/Image';
import Tooltip from '@mui/material/Tooltip';
import { green, red } from '@mui/material/colors';
import { GridCellEditStopReasons } from '@mui/x-data-grid';

const columns = [
  { field: 'fullName', headerName: 'Full Name', flex: 1, editable: false },
  { field: 'clockinTime', headerName: 'Clock-in Time', flex: 1, editable: true },
  { field: 'clockoutTime', headerName: 'Clock-out Time', flex: 1, editable: true },
  { field: 'propertyName', headerName: 'Property Name', flex: 1, editable: false },
  {
    field: 'beforeCompactorImg',
    headerName: 'Before Compactor',
    flex: 1,
    editable: false,
    renderCell: (params) => {
      const hasImage = !!params.row.beforeCompactorImg;
      return (
        <Tooltip title={hasImage ? "View Image" : "No Image"}>
          <ImageIcon
            onClick={() => hasImage && params.row.showImage(params.row.beforeCompactorImg)}
            style={{ cursor: hasImage ? 'pointer' : 'default', color: hasImage ? green[500] : red[500] }}
          />
        </Tooltip>
      );
    }
  },
  {
    field: 'afterCompactorImg',
    headerName: 'After Compactor',
    flex: 1,
    editable: false,
    renderCell: (params) => {
      const hasImage = !!params.row.afterCompactorImg;
      return (
        <Tooltip title={hasImage ? "View Image" : "No Image"}>
          <ImageIcon
            onClick={() => hasImage && params.row.showImage(params.row.afterCompactorImg)}
            style={{ cursor: hasImage ? 'pointer' : 'default', color: hasImage ? green[500] : red[500] }}
          />
        </Tooltip>
      );
    }
  }
];

const dateFilterOptions = [
  { label: "Clock-in Date", value: "clockinTime" },
  { label: "Clock-out Date", value: "clockoutTime" }
];

const ClockInOutsPage = () => {
  const [rows, setRows] = useState([]);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [logs, setLogs] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const [editRowsModel, setEditRowsModel] = useState({});
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const clockInOutsSnapshot = await getDocs(collection(db, 'clockInOuts'));
      const usersSnapshot = await getDocs(collection(db, 'team'));

      const userMap = usersSnapshot.docs.reduce((acc, doc) => {
        const userData = doc.data();
        acc[doc.id] = `${userData.firstName} ${userData.lastName}`;
        return acc;
      }, {});

      const allData = clockInOutsSnapshot.docs.map(doc => {
        const docData = doc.data();
        return {
          id: doc.id,
          fullName: userMap[docData.UserID] || '',
          clockinTime: docData.Clockin ? format(docData.Clockin.toDate(), 'hh:mm a') : '',
          clockoutTime: docData.Clockout ? format(docData.Clockout.toDate(), 'hh:mm a') : '',
          propertyName: docData['Property Name'] || '',
          beforeCompactorImg: docData.beforeTrashCompactor?.url || '',
          afterCompactorImg: docData.afterTrashCompactor?.url || '',
          showImage: handleShowImage
        };
      });
      setRows(allData);
    };

    fetchData();
  }, []);

  const logChange = async (id, changes) => {
    await addDoc(collection(db, 'logs'), {
      action: 'Update',
      category: 'ClockInOuts',
      changes,
      clockInOutId: id,
      timestamp: new Date()
    });
  };

  const handleProcessRowUpdate = async (newRow, oldRow) => {
    const { id, clockinTime, clockoutTime } = newRow;

    const clockInOutRef = doc(db, 'clockInOuts', id);
    const updatedData = {
      Clockin: clockinTime ? parse(clockinTime, 'hh:mm a', new Date()) : null,
      Clockout: clockoutTime ? parse(clockoutTime, 'hh:mm a', new Date()) : null,
    };

    await updateDoc(clockInOutRef, updatedData);
    await logChange(id, updatedData);
    return { ...oldRow, ...newRow };
  };

  const handleCellEditStop = (params, event) => {
    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
      setEditRowsModel({});
    }
  };

  const handleEditRowModelChange = (model) => {
    setEditRowsModel(model);
    setSaveButtonVisible(Object.keys(model).length > 0);
  };

  const handleSaveChanges = async () => {
    const updates = Object.values(editRowsModel).map(async ({ id, ...changes }) => {
      const row = rows.find((row) => row.id === id);
      const updatedRow = { ...row, ...changes };
      await handleProcessRowUpdate(updatedRow, row);
    });
    await Promise.all(updates);
    setEditRowsModel({});
    setSaveButtonVisible(false);
  };

  const fetchLogs = async () => {
    const q = query(collection(db, "logs"), orderBy("timestamp", "desc"), limit(10));
    const snapshot = await getDocs(q);
    const logEntries = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLogs(logEntries);
    setShowLogsModal(true);
  };

  const handleShowImage = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setImageModalOpen(true);
  };

  return (
    <div>
      <DataTable
        rows={rows}
        columns={columns}
        dateFilterOptions={dateFilterOptions}
        processRowUpdate={handleProcessRowUpdate}
        onCellEditStop={handleCellEditStop}
        onEditRowModelChange={handleEditRowModelChange}
        editRowsModel={editRowsModel}
      />
      <div className="controls">
        <button onClick={fetchLogs}>View Logs</button>
        <button>AI</button> {/* Placeholder for AI functionality */}
        {saveButtonVisible && <button onClick={handleSaveChanges}>Save Changes</button>}
      </div>
      <Modal isOpen={showLogsModal} onClose={() => setShowLogsModal(false)}>
        <h2>Recent Logs</h2>
        {logs.map(log => (
          <div key={log.id}>
            <p>Action: {log.action}</p>
            <p>Category: {log.category}</p>
            <p>Timestamp: {log.timestamp?.toDate().toString()}</p>
          </div>
        ))}
      </Modal>
      <Modal isOpen={imageModalOpen} onClose={() => setImageModalOpen(false)}>
        <img src={currentImageUrl} alt="Compactor" style={{ width: '100%' }} />
      </Modal>
    </div>
  );
};

export default ClockInOutsPage;
