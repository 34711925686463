import React, { useState } from 'react';
import { db, storage } from '../../../firebase';
import { collection, addDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const violationRules = [
    "The trash bag is covered in liquid, infested with insects (such as maggots), or soaking wet.",
    "Trash bag is torn, ripped, or has broken strings.",
    "Trash bag contains cat litter and may either be LEAKING, OVERWEIGHT, or NOT DOUBLE-BAGGED.",
    "All Trash Must Be Bagged And Tied (keeps Trash From Spilling And Dripping Onto The Concrete).",
    "No grocery bags or paper sacks (they bust, they are hard to carry and they leak).",
    "Recycling must be in a clear or blue bag (so that we can identify and dispose of it).",
    "No Oversized items (Example: Furniture, Appliances, Large Unflattened Boxes, Bulk Removal, Etc.).",
    "Late bag(s): Trash must be put out between 6 to 8 pm for service.",
    "No more than two (2) bags per night per unit.",
    "Trash Bag not in provided container (because this leaves stains on the concrete).",
    "No heavy or hazardous or unbagged objects.",
    "No Loose Trash Items Inside The Bin (Item Is Not Bagged & Can Not Collect).",
    "Cardboard must be broken down/bundled (it's much easier to carry this way).",
    "Trash Bag Is Leaking Due To The Contents It Holds.",
    "Loose trash.",
    "Trash bag(s) not tied.",
    "Over trash bag limit.",
    "Over trash bag weight limit.",
    "Recycling bag(s) not tied.",
    "Box(es) not broken down/flattened.",
    "Over recycling bag weight limit.",
    "Over cardboard limit.",
    "Trash not in/on trash bin."
];

const ViolationsForm = ({ property, employeeId, activeClockInId, onClose }) => {
    const [unitNumber, setUnitNumber] = useState('');
    const [selectedViolation, setSelectedViolation] = useState('');
    const [collected, setCollected] = useState(false);
    const [image, setImage] = useState(null);
    const [uploading, setUploading] = useState(false); // State to manage upload status

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true); // Start upload
    
        if (!property) {
            console.error('Error adding violation: Property is undefined');
            setUploading(false);
            return;
        }
    
        let imageUrl = '';
    
        if (image) {
            const storageRef = ref(storage, `violations/${image.name}`);
            await uploadBytes(storageRef, image);
            imageUrl = await getDownloadURL(storageRef);
        }
    
        const violationDoc = {
            Property: property,
            EmployeeID: employeeId,
            UnitNumber: unitNumber,
            ViolationRule: selectedViolation,
            Collected: collected,
            image: imageUrl, // Store the URL of the uploaded image
            createdAt: new Date(),
            logId: activeClockInId
        };
    
        try {
            const docRef = await addDoc(collection(db, 'violations'), violationDoc);
            await updateDoc(docRef, { logId: activeClockInId });
    
            // Notification for successful submission
            new Notification('Violation Reported', {
                body: 'Violation successfully submitted!',
            });
    
            onClose();
        } catch (error) {
            console.error('Error adding violation: ', error);
        } finally {
            setUploading(false); // End upload
        }
    };
    

    return (
        <div className="violations-form-container">
            <form onSubmit={handleSubmit} className="violations-form">
                <label>
                    Unit Number:
                    <input type="text" value={unitNumber} onChange={(e) => setUnitNumber(e.target.value)} required />
                </label>
                <label>
                    Select Violation Rule:
                    <select value={selectedViolation} onChange={(e) => setSelectedViolation(e.target.value)} required>
                        <option value="">Select a violation</option>
                        {violationRules.map((rule, index) => (
                            <option key={index} value={rule}>{rule}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Trash Collected:
                    <label className="switch">
                        <input type="checkbox" checked={collected} onChange={(e) => setCollected(e.target.checked)} />
                        <span className="slider round"></span>
                    </label>
                </label>
                <label>
                    Image:
                    <input type="file" accept="image/*" onChange={handleImageChange} required />
                </label>
                <button type="submit" disabled={uploading}>{uploading ? 'Uploading...' : 'Submit'}</button>
            </form>
        </div>
    );
};

export default ViolationsForm;
