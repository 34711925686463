import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import HomeIcon from '../../../assets/BlackHome.svg';
import LogsIcon from '../../../assets/logs.svg';
import ProfileIcon from '../../../assets/profile.svg';
import './MobileNav.css'; // Create a CSS file for styling

const MobileNav = () => {
  const location = useLocation();

  return (
    <div className="mobile-menu">
      <NavLink
        to="/logs"
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <img src={LogsIcon} alt="Logs" />
      </NavLink>
      <NavLink
        to="/"
        className={({ isActive }) => (location.pathname === '/' ? 'active' : '')}
      >
        <img src={HomeIcon} alt="Home" />
      </NavLink>
      <NavLink
        to="/profile"
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <img src={ProfileIcon} alt="Profile" />
      </NavLink>
    </div>
  );
};

export default MobileNav;
