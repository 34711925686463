import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getDocs, collection, addDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { db } from '../../../firebase';
import { getDistance } from 'geolib';
import ClockIn from '../components/ClockIn';
import ClockOut from '../components/ClockOut';
import EmployeeToolkit from '../components/EmployeeToolkit';
import Modal from '../../../components/Modal';
import SpecialistGrid from '../components/SpecialistGrid';
import './Profile.css';

const EmployeeDashboard = () => {
    const auth = getAuth();
    const [user, setUser] = useState(null);
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [clockInData, setClockInData] = useState(null);
    const [activeClockInId, setActiveClockInId] = useState(null);
    const [error, setError] = useState(null);
    const [duration, setDuration] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [missingItems, setMissingItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const timerRef = useRef(null);

    useEffect(() => {
        onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                const userDoc = await getDoc(doc(db, 'team', currentUser.uid));
                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                }

                const q = query(collection(db, 'clockInOuts'), where("UserID", "==", currentUser.uid), where("Clockout", "==", null));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    setClockInData(doc.data());
                    setActiveClockInId(doc.id);
                }
            }
            setLoading(false); // Set loading to false after data fetching is complete
        });
    }, [auth]);

    useEffect(() => {
        if (clockInData && clockInData.Clockin && !clockInData.Clockout) {
            const startTime = clockInData.Clockin.toDate();
            timerRef.current = setInterval(() => {
                const now = new Date();
                const diff = Math.floor((now - startTime) / 1000);
                const hours = Math.floor(diff / 3600);
                const minutes = Math.floor((diff % 3600) / 60);
                const seconds = diff % 60;
                setDuration(`${hours}h ${minutes}m ${seconds}s`);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setDuration(null);
        }

        return () => clearInterval(timerRef.current);
    }, [clockInData]);

    useEffect(() => {
        const fetchProperties = async () => {
            const propertiesSnapshot = await getDocs(collection(db, 'communities'));
            const propertiesData = propertiesSnapshot.docs.map(doc => ({
                label: doc.id,
                value: doc.id,
                ...doc.data(),
            }));
            setProperties(propertiesData);
        };

        fetchProperties();
    }, []);

    const handleClockIn = async () => {
        if (selectedProperty && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const propertyRef = doc(db, 'communities', selectedProperty.value);
                const propertyDoc = await getDoc(propertyRef);

                if (propertyDoc.exists()) {
                    const propertyData = propertyDoc.data();
                    const distance = getDistance(
                        { latitude, longitude },
                        { latitude: parseFloat(propertyData.latitude), longitude: parseFloat(propertyData.longitude) }
                    );

                    if (distance <= 100) {
                        const now = new Date();
                        const nowTimestamp = Timestamp.fromDate(now);

                        const clockInRef = await addDoc(collection(db, 'clockInOuts'), {
                            Clockin: nowTimestamp,
                            Clockout: null,
                            UserID: user.uid,
                            'Property Timezone': propertyData.timezone || 'UTC',
                            'Property Name': selectedProperty.value,
                            logId: ""
                        });

                        await updateDoc(clockInRef, { logId: clockInRef.id });

                        setClockInData({
                            Clockin: nowTimestamp,
                            Clockout: null,
                            UserID: user.uid,
                            'Property Timezone': propertyData.timezone || 'UTC',
                            'Property Name': selectedProperty.value,
                            logId: clockInRef.id
                        });
                        setActiveClockInId(clockInRef.id);

                        setModalMessage(`Clocked in at ${now.toLocaleString()} at ${selectedProperty.label}`);
                        setTimeout(() => setModalMessage(null), 3000);
                    } else {
                        setError('You are not within the property radius.');
                    }
                }
            });
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    const handleClockOut = async () => {
        const now = new Date();
        const nowTimestamp = Timestamp.fromDate(now);

        const missingItemsList = await checkMissingItems();
        if (missingItemsList.length > 0) {
            setMissingItems(missingItemsList);
            return;
        }

        await completeClockOut(nowTimestamp);
    };

    const completeClockOut = async (timestamp) => {
        if (activeClockInId) {
            const clockInRef = doc(db, 'clockInOuts', activeClockInId);
            await updateDoc(clockInRef, { Clockout: timestamp });
            setClockInData(null);
            setActiveClockInId(null);
            setModalMessage(`Clocked out at ${new Date(timestamp.toDate()).toLocaleString()}. Duration: ${duration}`);
            setTimeout(() => setModalMessage(null), 3000);
            setMissingItems([]);
        }
    };

    const checkMissingItems = async () => {
        const missingItemsList = [];
        if (activeClockInId) {
            const clockInRef = doc(db, 'clockInOuts', activeClockInId);
            const clockInDoc = await getDoc(clockInRef);

            if (clockInDoc.exists()) {
                const data = clockInDoc.data();
                if (!data.beforeTrashCompactor) {
                    missingItemsList.push('Before Trash Compactor Photo');
                }
                if (!data.afterTrashCompactor) {
                    missingItemsList.push('After Trash Compactor Photo');
                }
            }

            const q = query(collection(db, 'bintagscans'), where('logID', '==', activeClockInId));
            const scannedBinTagsSnapshot = await getDocs(q);
            const binTagsSnapshot = await getDocs(collection(db, 'communities', clockInData['Property Name'], 'bintags'));
            
            const scannedBinTags = new Set(scannedBinTagsSnapshot.docs.map(doc => doc.data().scanResult));
            binTagsSnapshot.docs.forEach(doc => {
                if (!scannedBinTags.has(doc.id)) {
                    missingItemsList.push(`Bin Tag: ${doc.data().name}`);
                }
            });
        }
        return missingItemsList;
    };

    const savePhoto = async (photoStage, photoData) => {
        const storage = getStorage();
        const storageRef = ref(storage, `compactor-photos/${user.uid}/${photoStage}-${Date.now()}.png`);

        await uploadString(storageRef, photoData, 'data_url');
        const downloadURL = await getDownloadURL(storageRef);

        if (activeClockInId) {
            const clockInRef = doc(db, 'clockInOuts', activeClockInId);
            const timestamp = new Date().toISOString();
            const updateData = {
                [`${photoStage}TrashCompactor`]: {
                    url: downloadURL,
                    timestamp
                }
            };

            await updateDoc(clockInRef, updateData);
        }
    };

    const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
    };

    if (loading) {
        return <div></div>; // Show a loading state while data is being fetched
    }

    return (
        <div className="employee-dashboard-container">
            {clockInData && clockInData.Clockin && !clockInData.Clockout ? (
                <>
                    <EmployeeToolkit savePhoto={savePhoto} property={selectedProperty?.value} employeeId={user?.uid} activeClockInId={activeClockInId} />
                    <ClockOut handleClockOut={handleClockOut} duration={duration} />
                </>
            ) : (
                <ClockIn
                    properties={properties}
                    selectedProperty={selectedProperty}
                    setSelectedProperty={setSelectedProperty}
                    handleClockIn={handleClockIn}
                    error={error}
                    toggleDropdown={toggleDropdown}
                    showDropdown={showDropdown}
                    user={userData}
                />
            )}
            {userData && userData.tier === 'District Manager' && (
                <SpecialistGrid />
            )}
            {modalMessage && (
                <Modal isOpen={true} onClose={() => setModalMessage(null)}>
                        <p>{modalMessage}</p>
                </Modal>
            )}
            {missingItems.length > 0 && (
                <Modal isOpen={true} onClose={() => setMissingItems([])}>
                        <h2 className="missing-title">Missing Items</h2>
                        <ul className="missing-list">
                            {missingItems.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                        <button className="delete-button" onClick={() => completeClockOut(Timestamp.fromDate(new Date()))}>Clock Out Anyway</button>
                </Modal>
            )}
        </div>
    );
};

export default EmployeeDashboard;
