import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataGrid';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { format } from 'date-fns';

const columns = [
  { field: 'logID', headerName: 'Log ID', flex: 1 },
  { field: 'scanResult', headerName: 'Scan Result', flex: 1 },
  { field: 'timestamp', headerName: 'Timestamp', flex: 1 },
];

const dateFilterOptions = [
  { label: "Timestamp", value: "timestamp" },
];

const BinTagScansReport = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const bintagScansRef = collection(db, 'bintagscans');
      const querySnapshot = await getDocs(bintagScansRef);
      const bintagScansList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          logID: data.logID || '',
          scanResult: data.scanResult || '',
          timestamp: data.timestamp ? format(data.timestamp.toDate(), 'MM/dd/yyyy hh:mm a') : '',
        };
      });

      setRows(bintagScansList);
    };

    fetchData();
  }, []);

  return (
    <DataTable
      rows={rows}
      columns={columns}
      dateFilterOptions={dateFilterOptions}
    />
  );
};

export default BinTagScansReport;
