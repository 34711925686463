import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import MobileNav from './nav/Nav';
import WebNav from './nav/WebNav';
import Home from './pages/EmployeeDashboard';
import Logs from './pages/Logs';
import Profile from './pages/Profile';
import EmployeeStatus from './pages/EmployeeStatus';
import useWindowSize from '../../hooks/useWindowSize';
import './EmployeeHub.css';

const EmployeeHub = () => {
    const { width } = useWindowSize();
    const navigate = useNavigate();

    const handlers = useSwipeable({
        onSwipedLeft: () => navigateNext(),
        onSwipedRight: () => navigatePrev(),
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    const navigateNext = () => {
        const currentPath = window.location.pathname;
        if (currentPath.endsWith('/')) navigate('/logs');
        else if (currentPath.endsWith('/logs')) navigate('/profile');
    };

    const navigatePrev = () => {
        const currentPath = window.location.pathname;
        if (currentPath.endsWith('/profile')) navigate('/');
        else if (currentPath.endsWith('/')) navigate('/logs');
    };

    return (
        <div className="employee-hub-container" {...handlers}>
            <div className="content-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="logs" element={<Logs />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="profile/:employeeId" element={<EmployeeStatus />} />
                </Routes>
            </div>
            {width < 768 ? <MobileNav /> : <WebNav />}
        </div>
    );
};

export default EmployeeHub;
