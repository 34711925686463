import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import ProfileInfo from './communities/ProfileInfo';
import MonthlyReports from './communities/MonthlyReports';
import BinTags from './communities/BinTags';
import './PropertyProfile.css';
import { db } from '../firebase';
import Loader from '../components/Loader'; // Import the Loader component

const PropertyProfile = () => {
  const { propertyName } = useParams();
  const [property, setProperty] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      const docRef = doc(db, "communities", propertyName);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProperty(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchProperty();
  }, [propertyName]);

  if (!property) {
    return <Loader />; // Use the Loader component
  }

  return (
    <div className="property-details-container">
      <ProfileInfo property={property} />
      <div className="content-section">
        <MonthlyReports propertyName={propertyName} />  
        <BinTags propertyName={propertyName} />
      </div>
    </div>
  );
};

export default PropertyProfile;
