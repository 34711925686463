import React from 'react';
import { bouncy } from 'ldrs'
import './Loader.css'; // Create this file for loader styles

bouncy.register()

const Loader = () => {
  return (
    <div className="loader-container">
      <l-bouncy
        size="45"
        speed="1.75" 
        color="#1D9100" 
      ></l-bouncy>
    </div>
  );
};

export default Loader;