// MobileNav.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '../../assets/Home.svg';
import CommunityIcon from '../../assets/community.svg';
import TeamIcon from '../../assets/team.svg';
import SearchIcon from '../../assets/search.svg';
import './MobileNav.css';

const MobileNav = () => {
    const location = useLocation();

    const navItems = [
        { path: "/admin", icon: HomeIcon, label: "Home" },
        { path: "/admin/communities", icon: CommunityIcon, label: "Communities" },
        { path: "/admin/team", icon: TeamIcon, label: "Team" },
        { path: "/admin/data-input", icon: SearchIcon, label: "Tracking" },
    ];

    return (
        <nav className="mobile-nav">
            {navItems.map((item) => (
                <Link 
                    key={item.path} 
                    to={item.path} 
                    className={`mobile-nav-link ${location.pathname === item.path ? "mobile-selected" : ""}`}
                >
                    <img src={item.icon} alt={item.label} className="mobile-nav-icon" />
                    <span>{item.label}</span>
                </Link>
            ))}
        </nav>
    );
}

export default MobileNav;