import React from 'react';
import './Clock.css';
import logo from '../../../assets/greenlogo.svg'; // Adjust the path according to your file structure

const ClockIn = ({ properties, selectedProperty, setSelectedProperty, handleClockIn, error, toggleDropdown, showDropdown, user }) => {
    if (!user) {
        return null;
    }

    return (
        <div className="clock-in-container">
            <img src={logo} alt="Logo" className="mobile-logo" />
            <h1 className="employee-hero">Hi, {user.firstName} {user.lastName}</h1>
            <div className="community-selector">
                <span className="dropdown-link" onClick={toggleDropdown}>
                    {selectedProperty ? selectedProperty.label : "Select Community"}
                </span>
                {showDropdown && (
                    <div className="dropdown-options">
                        {properties.map((property) => (
                            <div
                                key={property.value}
                                onClick={() => {
                                    setSelectedProperty(property);
                                    toggleDropdown();
                                }}
                                className="dropdown-option"
                            >
                                {property.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="clock">
                <p className="time">{new Date().toLocaleTimeString()}</p>
                <p className="date">{new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
            </div>
            <button onClick={handleClockIn} disabled={!selectedProperty} className="clock-in-button">
                Clock in
            </button>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default ClockIn;