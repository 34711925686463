import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { db } from '../../../firebase';
import './Logs.css';

const Logs = () => {
    const [user, setUser] = useState(null);
    const [logs, setLogs] = useState([]);
    const [filter, setFilter] = useState('all');
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [colorMap, setColorMap] = useState({});

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userDoc = await getDoc(doc(db, 'team', currentUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.tier === 'District Manager') {
                        const specialists = userData.assignedSpecialists || [];
                        setTeamMembers([{ id: currentUser.uid, ...userData }, ...specialists]);
                        fetchAllLogs([currentUser.uid, ...specialists.map(s => s.id)]);
                    } else {
                        fetchLogs(currentUser.uid);
                    }
                }
            }
        });
        return () => unsubscribe();
    }, []);

    const fetchLogs = async (userId) => {
        const logsData = await fetchUserLogs(userId);
        setLogs(logsData);
    };

    const fetchAllLogs = async (userIds) => {
        let allLogs = [];
        for (const userId of userIds) {
            const logsData = await fetchUserLogs(userId);
            allLogs = [...allLogs, ...logsData];
        }
        setLogs(allLogs);
        generateColorMap(userIds);
    };

    const fetchUserLogs = async (userId) => {
        const violationsQuery = query(collection(db, 'violations'), where('EmployeeID', '==', userId));
        const clockInsQuery = query(collection(db, 'clockInOuts'), where('UserID', '==', userId));
        const binTagScansQuery = query(collection(db, 'bintagscans'), where('EmployeeID', '==', userId));
        const logsData = [];

        const [violationsSnapshot, clockInsSnapshot, binTagScansSnapshot] = await Promise.all([
            getDocs(violationsQuery),
            getDocs(clockInsQuery),
            getDocs(binTagScansQuery)
        ]);

        violationsSnapshot.forEach(doc => logsData.push({ ...doc.data(), type: 'violation', id: doc.id, employeeId: userId }));
        clockInsSnapshot.forEach(doc => logsData.push({ ...doc.data(), type: 'clockInOut', id: doc.id, employeeId: userId }));
        binTagScansSnapshot.forEach(doc => logsData.push({ ...doc.data(), type: 'binTagScan', id: doc.id, employeeId: userId }));

        return logsData;
    };

    const generateColorMap = (userIds) => {
        const newColorMap = {};
        userIds.forEach(id => {
            newColorMap[id] = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        });
        setColorMap(newColorMap);
    };

    const filterLogs = (log) => {
        if (filter === 'all' && selectedEmployees.length === 0) return true;
        if (filter !== 'all' && selectedEmployees.length === 0) return log.type === filter;
        if (filter === 'all' && selectedEmployees.length > 0) return selectedEmployees.includes(log.employeeId);
        return log.type === filter && selectedEmployees.includes(log.employeeId);
    };

    const formatTimestamp = (timestamp) => {
        if (timestamp && timestamp.toDate) {
            const date = timestamp.toDate();
            return date.toLocaleString();
        }
        return 'Invalid timestamp';
    };

    return (
        <div className="employee-container">
            <div className="filter-container">
                <Autocomplete
                    multiple
                    id="employee-filter"
                    options={teamMembers}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    value={selectedEmployees.map(id => teamMembers.find(member => member.id === id))}
                    onChange={(event, newValue) => {
                        setSelectedEmployees(newValue.map(member => member.id));
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={option.id}
                                label={`${option.firstName} ${option.lastName}`}
                                {...getTagProps({ index })}
                                style={{ backgroundColor: colorMap[option.id], color: '#fff' }}
                            />
                        ))
                    }
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select Employees" placeholder="Employees" />}
                    style={{ width: 200, maxHeight: 100 }}
                />
                <div className="filter-dropdown">
                    <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                        <option value="all">All</option>
                        <option value="violation">Violations</option>
                        <option value="clockInOut">Clock-Ins/Outs</option>
                        <option value="binTagScan">Bin Tag Scans</option>
                    </select>
                </div>
            </div>
            <div className="logs-grid">
                {logs.filter(filterLogs).map(log => (
                    <div key={log.id} className="log-item" style={{ borderLeft: `4px solid ${colorMap[log.employeeId]}` }}>
                        {log.type === 'clockInOut' && (
                            <>
                                <h3>Clock-In/Out</h3>
                                <p><strong>Property Name:</strong> {log['Property Name']}</p>
                                <p><strong>Clock In:</strong> {formatTimestamp(log.Clockin)}</p>
                                <p><strong>Clock Out:</strong> {formatTimestamp(log.Clockout)}</p>
                            </>
                        )}
                        {log.type === 'violation' && (
                            <>
                                <h3>Violation</h3>
                                <p><strong>Unit Number:</strong> {log.UnitNumber}</p>
                                <p><strong>Reason:</strong> {log.ViolationRule}</p>
                                <p><strong>Timestamp:</strong> {formatTimestamp(log.createdAt)}</p>
                                {log.image && <img src={log.image} alt="Violation" className="log-image" />}
                            </>
                        )}
                        {log.type === 'binTagScan' && (
                            <>
                                <h3>Bin Tag Scan</h3>
                                <p><strong>Property Name:</strong> {log.propertyName}</p>
                                <p><strong>Bin Tag Name:</strong> {log.scanResult}</p>
                                <p><strong>Timestamp:</strong> {formatTimestamp(log.timestamp)}</p>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Logs;
