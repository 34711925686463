import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const PropertyAdmin = () => {
    const { propertyName } = useParams();
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === 'admin-password') {
            setAuthenticated(true);
        } else {
            alert('Invalid password');
        }
    };

    return (
        <div className="property-admin-container">
            <h1>Admin Access for {propertyName}</h1>
            {!authenticated ? (
                <form onSubmit={handlePasswordSubmit}>
                    <label>
                        Password:
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    <button type="submit">Submit</button>
                </form>
            ) : (
                <div className="admin-content">
                    {/* Add more in-depth information and management tools here */}
                    <p>In-depth information and management tools will be added here.</p>
                </div>
            )}
        </div>
    );
};

export default PropertyAdmin;
