import React, { useEffect, useState } from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../firebase';
import QRCode from 'qrcode'; // Import qrcode library
import Modal from '../../components/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard'; // Import CopyToClipboard
import './BinTags.css';
import addIcon from '../../assets/add.svg'; // Add icon
import copyIcon from '../../assets/copy.svg'; // Add copy icon

const BinTags = () => {
    const { propertyName } = useParams();
    const navigate = useNavigate();
    const [binTags, setBinTags] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [newBinTagName, setNewBinTagName] = useState('');
    const [selectedBinTag, setSelectedBinTag] = useState(null);
    const [activeStatus, setActiveStatus] = useState(false);
    const [originalActiveStatus, setOriginalActiveStatus] = useState(false); // Track original active status
    const [changesMade, setChangesMade] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        const fetchBinTags = async () => {
            const binTagsCollection = collection(db, 'communities', propertyName, 'bintags');
            const binTagsSnapshot = await getDocs(binTagsCollection);
            setBinTags(binTagsSnapshot.docs.map(docRef => ({ id: docRef.id, ...docRef.data() })));
        };

        fetchBinTags();
    }, [propertyName]);

    const handleAddBinTag = async (e) => {
        e.preventDefault();
        const trimmedBinTagName = newBinTagName.trim(); // Trim whitespace from input
        const existingBinTag = binTags.find((binTag) => binTag.name === trimmedBinTagName);
    
        if (existingBinTag) {
            notify(`A bin tag with the name "${trimmedBinTagName}" already exists.`);
            return;
        }
    
        const binTagsCollection = collection(db, 'communities', propertyName, 'bintags');
        const docRef = await addDoc(binTagsCollection, {
            name: trimmedBinTagName,
            createdAt: serverTimestamp(),
            active: false,
            building: propertyName
        });
    
        // Update the URL to use the document ID
        const url = `${window.location.origin}/community/${propertyName}/bintag/${docRef.id}`;
        await updateDoc(docRef, { url });  // Update the document with the correct URL
    
        // Generate QR code data URL
        const qrCodeURL = await generateQRCodeURL(docRef.id);
        await updateDoc(docRef, { qrCodeURL });
    
        const newBinTag = {
            id: docRef.id,
            name: trimmedBinTagName,
            createdAt: serverTimestamp(),
            active: false,
            building: propertyName,
            url,  // Use the new URL with the docRef.id
            qrCodeURL
        };
    
        setBinTags([...binTags, newBinTag]);
        setModalOpen(false);
        setNewBinTagName('');
        notify(`Bin tag "${trimmedBinTagName}" successfully added`);
    };
    

    const generateQRCodeURL = async (id) => {
        try {
            const qrCodeDataURL = await QRCode.toDataURL(id, {
                width: 256,
                margin: 1,
                color: {
                    dark: '#000000',
                    light: '#ffffff'
                }
            });
            return qrCodeDataURL;
        } catch (error) {
            console.error('Failed to generate QR code', error);
            return '';
        }
    };

    const handleBinTagClick = (binTag) => {
        setSelectedBinTag(binTag);
        setActiveStatus(binTag.active);
        setOriginalActiveStatus(binTag.active); // Store original active status
    };

    const closeBinTagModal = () => {
        setSelectedBinTag(null);
        setChangesMade(false);
        setCopySuccess('');
    };

    const handleStatusChange = () => {
        const newStatus = !activeStatus;
        setActiveStatus(newStatus);
        setChangesMade(newStatus !== originalActiveStatus); // Compare with original active status
    };

    const handleSaveChanges = async () => {
        if (selectedBinTag) {
            const binTagRef = doc(db, 'communities', propertyName, 'bintags', selectedBinTag.id);
            await updateDoc(binTagRef, { active: activeStatus });
            const updatedBinTags = binTags.map(binTag => binTag.id === selectedBinTag.id ? { ...binTag, active: activeStatus } : binTag);
            setBinTags(updatedBinTags);
            setSelectedBinTag({ ...selectedBinTag, active: activeStatus });
            setChangesMade(false);
        }
    };

    const handleDeleteBinTag = async () => {
        if (selectedBinTag) {
            const binTagRef = doc(db, 'communities', propertyName, 'bintags', selectedBinTag.id);
            await deleteDoc(binTagRef);
            setBinTags(binTags.filter(binTag => binTag.id !== selectedBinTag.id));
            setSelectedBinTag(null);
        }
    };

    const notify = (message) => {
        if (Notification.permission === 'granted') {
            new Notification(message);
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    new Notification(message);
                }
            });
        }
    };

    const handleCopy = () => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000);
    };

    return (
        <div className="bin-tags-container">
            <div className="bin-tags-header">
                <h2>Bin Tags</h2>
                <img src={addIcon} alt="Add Bin Tag" className="add-bin-tag-icon" onClick={() => setModalOpen(true)} />
            </div>
            {binTags.length === 0 ? (
                <button className="add-bin-tag-button" onClick={() => setModalOpen(true)}>
                    <div className="add-bin-tag-content">
                        <img src={addIcon} alt="Add Bin Tag" className="add-icon" />
                        Add Bin Tag
                    </div>
                </button>
            ) : (
                <>
                    <div className="bin-tags-list">
                        {binTags.map((binTag) => (
                            <div key={binTag.id} className="bin-tag" onClick={() => handleBinTagClick(binTag)}>
                                <img src={binTag.qrCodeURL} alt={`QR code for ${binTag.name}`} className="qr-code" />
                                <div className={`status-indicator ${binTag.active ? 'active' : 'inactive'}`}></div>
                                <div className="bin-tag-name">{binTag.name}</div>
                            </div>
                        ))}
                    </div>
                    {binTags.length > 4 && (
                        <div className="view-all-container">
                            <button className="view-all-button" onClick={() => navigate(`/properties/${propertyName}/bintags`)}>
                                View All
                            </button>
                        </div>
                    )}
                </>
            )}
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <form onSubmit={handleAddBinTag} className="modal-form">
                    <label>
                        Bin Tag Name:
                        <input type="text" value={newBinTagName} onChange={(e) => setNewBinTagName(e.target.value)} required />
                    </label>
                    <button type="submit">Add Bin Tag</button>
                </form>
            </Modal>
            {selectedBinTag && (
                <Modal isOpen={true} onClose={closeBinTagModal}>
                    <div className="bin-tag-details">
                        <img src={selectedBinTag.qrCodeURL} alt={`QR code for ${selectedBinTag.name}`} className="qr-code-large" />
                        <p><strong>Name:</strong> {selectedBinTag.name}</p>
                        <p><strong>ID:</strong> {selectedBinTag.id}</p>
                        <p><strong>Building:</strong> {selectedBinTag.building}</p>
                        <p><strong>Created At:</strong> {selectedBinTag.createdAt.toDate().toLocaleDateString()}</p>
                        <p><strong>Status:</strong>
                            <label className="switch">
                                <input type="checkbox" checked={activeStatus} onChange={handleStatusChange} />
                                <span className="slider round"></span>
                            </label>
                        </p>
                        <div className="copy-url">
                            <CopyToClipboard text={selectedBinTag.url} onCopy={handleCopy}>
                                <button className="copy-button">
                                    <img src={copyIcon} alt="Copy" className="copy-icon" />
                                    Copy URL
                                </button>
                            </CopyToClipboard>
                            {copySuccess && <span className="copy-success">{copySuccess}</span>}
                        </div>
                        <div className="edit-buttons">
                            {changesMade && <button className="save-button" onClick={handleSaveChanges}>Save</button>}
                            <button className="delete-button" onClick={handleDeleteBinTag}>Delete</button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default BinTags;
