import React, { useState } from 'react';
import Modal from '../../components/Modal';
import './ProfileInfo.css';
import editIcon from '../../assets/edit.svg'; // Assuming the edit icon is in the assets folder

const ProfileInfo = ({ property }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleEditClick = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsEditModalOpen(false);
    };

    return (
        <div className="profile-info-container">
            <img 
                src={editIcon} 
                alt="Edit" 
                className="edit-icon" 
                onClick={handleEditClick} 
            />
            <h2 className="property-title">{property.propertyName}</h2>
            <div className="property-details">
                <div className="left-column">
                    <p><strong>Address:</strong> {property.address}</p>
                    <p><strong>State:</strong> {property.state}</p>
                    <p><strong>Unit #:</strong> {property.unitNumber}</p>
                    <p><strong>Email:</strong> <a href={`mailto:${property.propertyEmail}`}>{property.propertyEmail}</a></p>
                </div>
                <div className="right-column">
                    <p><strong>Phone:</strong> {property.phone}</p>
                    <p><strong>Apartment Type:</strong> {property.apartmentType}</p>
                    <p><strong>Temporary Password:</strong> <span className="blurred">{property.temporaryPassword}</span></p>
                    <p><strong>Property Map:</strong> <a href={property.propertyMap} target="_blank" rel="noopener noreferrer">View Map</a></p>
                </div>
            </div>
            <Modal isOpen={isEditModalOpen} onClose={handleCloseModal}>
                <form className="modal-form">
                    <label>
                        Property Name:
                        <input type="text" defaultValue={property.propertyName} />
                    </label>
                    <label>
                        Address:
                        <input type="text" defaultValue={property.address} />
                    </label>
                    <label>
                        State:
                        <input type="text" defaultValue={property.state} />
                    </label>
                    <label>
                        Unit #:
                        <input type="text" defaultValue={property.unitNumber} />
                    </label>
                    <label>
                        Email:
                        <input type="email" defaultValue={property.propertyEmail} />
                    </label>
                    <label>
                        Phone:
                        <input type="tel" defaultValue={property.phone} />
                    </label>
                    <label>
                        Zip Code:
                        <input type="text" defaultValue={property.zipCode} />
                    </label>
                    <label>
                        Apartment Type:
                        <input type="text" defaultValue={property.apartmentType} />
                    </label>
                    <label>
                        Temporary Password:
                        <input type="text" defaultValue={property.temporaryPassword} />
                    </label>
                    <button type="submit">Save</button>
                </form>
            </Modal>
        </div>
    );
};

export default ProfileInfo;
