import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const navigate = useNavigate();

  const navigateToCollection = (path, categoryClass) => {
    navigate(path, { state: { categoryClass } });
  };

  return (
    <div className="hub-container">
      <div className="hub-item daily-report" onClick={() => navigateToCollection('/admin/daily-report')}>
        Daily Report
      </div>
      <div className="hub-item clock-in-outs" onClick={() => navigateToCollection('/admin/clock-in-outs')}>
        Clock In-Outs
      </div>
      <div className="hub-item services" onClick={() => navigateToCollection('/admin/services')}>
        BinTags
      </div>
      <div className="hub-item units" onClick={() => navigateToCollection('/admin/units')}>
        Units
      </div>
      <div className="hub-item violations" onClick={() => navigateToCollection('/admin/violations')}>
        Violations
      </div>
    </div>
  );
};

export default Home;
