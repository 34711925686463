import React, { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DateRangePicker } from 'rsuite';
import './Table.css';
import 'rsuite/dist/rsuite.min.css';

const DataTable = ({ rows, columns, dateFilterOptions, processRowUpdate }) => {
  const [filterText, setFilterText] = useState('');
  const [sortModel, setSortModel] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFilterColumn, setDateFilterColumn] = useState(dateFilterOptions.length > 0 ? dateFilterOptions[0].value : '');

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const sortedRows = useMemo(() => {
    return [...rows].sort((a, b) => {
      for (const sort of sortModel) {
        const comparer = sort.sort === 'asc' ? 1 : -1;
        if (a[sort.field] < b[sort.field]) return -1 * comparer;
        if (a[sort.field] > b[sort.field]) return 1 * comparer;
      }
      return 0;
    });
  }, [rows, sortModel]);

  const filteredSortedRows = useMemo(() => {
    return sortedRows.filter(row => {
      const includeByText = Object.values(row).some(value => 
        String(value).toLowerCase().includes(filterText.toLowerCase())
      );
      if (dateRange[0] && dateRange[1] && dateFilterColumn) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        const dateValue = new Date(row[dateFilterColumn]);
        return includeByText && (dateValue >= startDate && dateValue <= endDate);
      }
      return includeByText;
    });
  }, [sortedRows, filterText, dateRange, dateFilterColumn]);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const handleDateFilterColumnChange = (event) => {
    setDateFilterColumn(event.target.value);
  };

  return (
    <div style={{ margin: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <input
          type="text"
          value={filterText}
          onChange={handleFilterChange}
          placeholder="Search..."
          className="search-input"
        />
        {dateFilterOptions.length > 0 && (
          <div>
            <select value={dateFilterColumn} onChange={handleDateFilterColumnChange} style={{ marginBottom: '10px' }}>
              {dateFilterOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
            <DateRangePicker
              appearance="default"
              placeholder="Select Date Range"
              placement="auto"
              onChange={handleDateRangeChange}
              style={{ width: '90%' }}
            />
          </div>
        )}
      </div>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          className='table'
          columns={columns}
          rows={filteredSortedRows}
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          getRowId={(row) => row.id}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </div>
    </div>
  );
};

export default DataTable;
