import React, { useState, useEffect } from 'react';
import { doc, updateDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import defaultProfilePic from '../../assets/default.png';
import { Chip, Box, IconButton, Avatar } from '@mui/material';
import Modal from '../../components/Modal';
import AddIcon from '@mui/icons-material/Add';
import editIcon from '../../assets/edit.svg'; // Assuming the edit icon is in the assets folder
import AssignTeamMember from './AssignTeamMember';
import './EmployeeProfile.css';

const EmployeeProfile = ({ employee }) => {
    const { employeeId } = useParams();
    const [daysSinceJoined, setDaysSinceJoined] = useState('');
    const [properties, setProperties] = useState([]);
    const [assignedSpecialists, setAssignedSpecialists] = useState([]); // New state for assigned specialists
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [firstName, setFirstName] = useState(employee.firstName);
    const [lastName, setLastName] = useState(employee.lastName);
    const [email, setEmail] = useState(employee.email);
    const [phone, setPhone] = useState(employee.phone);
    const [tier, setTier] = useState(employee.tier);
    const [startDate, setStartDate] = useState(employee.startDate);

    useEffect(() => {
        const calculateDaysSinceJoined = (startDate) => {
            const joinDate = new Date(startDate);
            const currentDate = new Date();
            const differenceInTime = currentDate - joinDate;
            const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
            setDaysSinceJoined(`${differenceInDays} days ago`);
        };

        const fetchClockInData = async () => {
            const q = query(collection(db, 'clockInOuts'), where('UserID', '==', employeeId));
            const querySnapshot = await getDocs(q);
            const propertyNames = querySnapshot.docs.map(doc => doc.data()['Property Name']);
            const uniqueProperties = [...new Set(propertyNames)];
            setProperties(uniqueProperties);
        };

        const fetchAssignedSpecialists = async () => {
            const docRef = doc(db, "team", employeeId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setAssignedSpecialists(docSnap.data().assignedSpecialists || []);
            }
        };

        calculateDaysSinceJoined(employee.startDate);
        fetchClockInData();
        fetchAssignedSpecialists();
    }, [employeeId, employee.startDate]);

    const handleEditClick = () => {
        setIsEditModalOpen(true);
    };

    const handleAddTeamClick = () => {
        setIsAssignModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsEditModalOpen(false);
        setIsAssignModalOpen(false);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        const docRef = doc(db, "team", employeeId);
        await updateDoc(docRef, {
            firstName,
            lastName,
            email,
            phone,
            tier,
            startDate,
        });
        setIsEditModalOpen(false);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^(\d{3})(\d{3})(\d{4})$/;
        const match = phoneNumber.replace(/\D/g, '').match(phoneRegex);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="employee-profile-container">
            <div className="employee-image">
                <img 
                    src={employee.profilePic || defaultProfilePic} 
                    alt="Profile" 
                    className="employee-profile-pic" 
                />
            </div>
            <div className="employee-details">
                <h2 className='employee-username'>{employee.firstName} {employee.lastName}</h2>
                <p><strong>Tier:</strong> {employee.tier}</p>
                <p><strong>Email:</strong> <a href={`mailto:${employee.email}`}>{employee.email}</a></p>
                <p><strong>Phone:</strong> {formatPhoneNumber(employee.phone)}</p>
                <p>Joined {daysSinceJoined}</p>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                    {properties.map((property, index) => (
                        <Chip key={index} label={property} />
                    ))}
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                    {assignedSpecialists.map((specialist, index) => (
                        <Chip
                            key={index}
                            avatar={<Avatar src={specialist.profilePic || defaultProfilePic} />}
                            label={`${specialist.firstName} ${specialist.lastName}`}
                        />
                    ))}
                </Box>
                <div className="icon-buttons">
                    <img 
                        src={editIcon} 
                        alt="Edit" 
                        className="edit-icon" 
                        onClick={handleEditClick} 
                    />
                    {employee.tier === 'District Manager' && (
                        <div className="add-team-button-container">
                            <IconButton onClick={handleAddTeamClick}>
                                <AddIcon />
                            </IconButton>
                            <span>Add Team</span>
                        </div>
                    )}
                </div>
            </div>
            <Modal isOpen={isEditModalOpen} onClose={handleCloseModal}>
                <form className="modal-form" onSubmit={handleSave}>
                    <label>
                        First Name:
                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                    </label>
                    <label>
                        Last Name:
                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className={!isEmailValid(email) ? 'invalid' : ''}
                        />
                        {!isEmailValid(email) && <span className="error">Invalid email address</span>}
                    </label>
                    <label>
                        Phone:
                        <input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Tier:
                        <select value={tier} onChange={(e) => setTier(e.target.value)} required>
                            <option value="Collection Specialist">Collection Specialist</option>
                            <option value="District Manager">District Manager</option>
                        </select>
                    </label>
                    <label>
                        Start Date:
                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </label>
                    <button type="submit" disabled={!isEmailValid(email)}>Save</button>
                </form>
            </Modal>
            <Modal isOpen={isAssignModalOpen} onClose={handleCloseModal}>
                <AssignTeamMember employee={employee} setAssignedSpecialists={setAssignedSpecialists} />
            </Modal>
        </div>
    );
};

export default EmployeeProfile;
