import React, { useEffect, useRef } from 'react';

const AddressAutocomplete = ({ address, setAddress, setState, setZipCode, setLatitude, setLongitude }) => {
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.address_components) {
          const addressComponents = place.address_components.reduce((acc, component) => {
            component.types.forEach(type => {
              acc[type] = component.long_name;
            });
            return acc;
          }, {});

          setAddress(place.formatted_address);
          setState(addressComponents.administrative_area_level_1 || '');
          setZipCode(addressComponents.postal_code || '');

          if (place.geometry) {
            setLatitude(place.geometry.location.lat());
            setLongitude(place.geometry.location.lng());
          }
        }
      });
    }
  }, [setAddress, setState, setZipCode, setLatitude, setLongitude]);

  return (
    <input
      ref={autocompleteRef}
      type="text"
      placeholder="Enter address"
      value={address}
      onChange={(e) => setAddress(e.target.value)}
      className="address-input"
    />
  );
};

export default AddressAutocomplete;
