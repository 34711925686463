import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import HomeIcon from '../../assets/Home.svg';
import CommunityIcon from '../../assets/community.svg';
import TeamIcon from '../../assets/team.svg';
import { getAuth, signOut } from 'firebase/auth';
import SearchIcon from '../../assets/search.svg';
import SettingsIcon from '../../assets/settings.svg';
import LogoutIcon from '../../assets/logout.svg';
import './header.css';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/admin/login');
        } catch (error) {
            console.error('Logout Error:', error);
        }
    };

    return (
        <nav className="side-nav">
            <div className="wf-logo-container">
                <a href="https://www.wastefree.com" target="_blank" rel="noopener noreferrer" className="wf-logo-link">
                    <img src={Logo} alt='WasteFree Logo' className="wf-logo" />
                </a>
            </div>
            <div className="wf-nav-links">
                <Link to="/admin" className={`wf-nav-link ${location.pathname === "/admin" ? "wf-selected" : ""}`}>
                    <img src={HomeIcon} alt='Home' className="wf-nav-icon" />
                    <span>Home</span>
                </Link>
                <Link to="/admin/communities" className={`wf-nav-link ${location.pathname === "/admin/communities" ? "wf-selected" : ""}`}>
                    <img src={CommunityIcon} alt='Communities' className="wf-nav-icon" />
                    <span>Communities</span>
                </Link>
                <Link to="/admin/team" className={`wf-nav-link ${location.pathname === "/admin/team" ? "wf-selected" : ""}`}>
                    <img src={TeamIcon} alt='Team' className="wf-nav-icon" />
                    <span>Team</span>
                </Link>
                <Link to="/admin/data-input" className={`wf-nav-link ${location.pathname === "/admin/data-input" ? "wf-selected" : ""}`}>
                    <img src={SearchIcon} alt='Tracking' className="wf-nav-icon" />
                    <span>Tracking</span>
                </Link>
            </div>
            <div className="wf-nav-bottom">
                <Link to="/admin/settings" className="wf-nav-link">
                    <img src={SettingsIcon} alt='Settings' className="wf-nav-icon" />
                    <span>Settings</span>
                </Link>
                <button className="wf-nav-link wf-logout-btn" onClick={handleLogout}>
                    <img src={LogoutIcon} alt='Logout' className="wf-nav-icon" />
                    <span>Logout</span>
                </button>
            </div>
        </nav>
    );
}

export default Header;