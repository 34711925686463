import React from 'react';
import './Clock.css';

const ClockOut = ({ handleClockOut, duration }) => {
    return (
        <div className="clock-out-container">
            <button onClick={handleClockOut} className="clock-out-button">
                Clock Out
            </button>
            <div className="clock-in-timer">
                <p>{duration}</p>
            </div>
        </div>
    );
};

export default ClockOut;
