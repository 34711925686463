import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { endOfMonth, startOfMonth, format } from 'date-fns';
import { db } from '../../firebase';
import Modal from '../../components/Modal';
import './MonthlyReports.css';

const MonthlyReports = ({ propertyName }) => {
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [monthData, setMonthData] = useState(null);
    const [monthlyReport, setMonthlyReport] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchMonths = async () => {
            const propertyDocsQuery = query(collection(db, 'reports'), where('Property', '==', propertyName.trim()));
            const propertyDocsSnapshot = await getDocs(propertyDocsQuery);
            const availableMonths = new Set();
            propertyDocsSnapshot.forEach(doc => {
                const date = new Date(doc.data().Date);
                const month = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
                availableMonths.add(month);
            });
            setMonths([...availableMonths]);
        };

        fetchMonths();
    }, [propertyName]);

    const handleMonthClick = async (month) => {
        setSelectedMonth(month);
        const year = month.split('-')[0];
        const monthIndex = parseInt(month.split('-')[1]) - 1;
        const startDate = startOfMonth(new Date(year, monthIndex));
        const endDate = endOfMonth(new Date(year, monthIndex));
        const startDateStr = format(startDate, 'MM-dd-yyyy');
        const endDateStr = format(endDate, 'MM-dd-yyyy');

        const monthDataQuery = query(collection(db, 'reports'), 
            where('Property', '==', propertyName),
            where('Date', '>=', startDateStr),
            where('Date', '<=', endDateStr)
        );

        try {
            const monthDataSnapshot = await getDocs(monthDataQuery);
            const data = [];
            monthDataSnapshot.forEach(doc => {
                data.push(doc.data());
            });

            setMonthData(data);

            if (data.length > 0) {
                const validData = data.filter(d => d.RouteDuration && d.BinTagScannedPercent);
                const avgRouteDuration = calculateAverage(validData.map(report => parseFloat(report.RouteDuration || 0)));
                const avgBinTagPercent = calculateAverage(validData.map(report => parseFloat(report.BinTagScannedPercent)));
                const totalViolations = data.reduce((total, report) => total + (report.Violations || 0), 0);
                const collectionSpecialists = [...new Set(data.map(report => report.Employee))];

                setMonthlyReport({
                    avgRouteDuration,
                    avgBinTagPercent,
                    totalViolations,
                    collectionSpecialists
                });
            } else {
                setMonthlyReport(null);
            }

            setModalOpen(true);
        } catch (error) {
            console.error("Error fetching data: ", error);
            alert("Failed to fetch data. Check console for more details.");
        }
    };

    const calculateAverage = (values) => {
        const sum = values.reduce((total, value) => total + value, 0);
        const average = sum / values.length;
        return average.toFixed(2);
    };

    return (
        <div className="monthly-reports-container">
            <div className="report-section">
                <h2 className="report-title">Available Monthly Reports</h2>
                <div className="month-buttons">
                    {months.map((month, index) => (
                        <button key={index} className="month-button" onClick={() => handleMonthClick(month)}>
                            {month}
                        </button>
                    ))}
                </div>
            </div>
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                {selectedMonth && (
                    <div className="data-section">
                        <h2 className="data-title">Monthly Report for {selectedMonth}</h2>
                        {monthlyReport ? (
                            <div className="monthly-report">
                                <p className="report-detail">Avg Route Duration: {monthlyReport.avgRouteDuration} hours</p>
                                <p className="report-detail">Avg Bin Tag %: {monthlyReport.avgBinTagPercent}%</p>
                                <p className="report-detail">Total Violations: {monthlyReport.totalViolations}</p>
                                <p className="report-detail">Collection Specialists: {monthlyReport.collectionSpecialists.join(', ')}</p>
                            </div>
                        ) : (
                            <p>No data available for the selected month.</p>
                        )}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default MonthlyReports;
