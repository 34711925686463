import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, query, where, addDoc, doc, Timestamp, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './BonusReport.css';

const BonusReport = ({ employeeId }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthlyData, setMonthlyData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateDetails, setSelectedDateDetails] = useState(null);
  const [logs, setLogs] = useState([]);
  const [totalBinTags, setTotalBinTags] = useState(0);
  const [scannedBinTags, setScannedBinTags] = useState(0);
  const [totalViolations, setTotalViolations] = useState(0);
  const [takenViolations, setTakenViolations] = useState(0);
  const [daysMissed, setDaysMissed] = useState(0);

  useEffect(() => {
    fetchDataForMonth(currentMonth);
  }, [currentMonth, employeeId]);

  const fetchDataForMonth = async (month) => {
    if (!employeeId) return;
    resetState();

    try {
      const start = startOfMonth(month);
      const end = endOfMonth(month);

      const startTimestamp = Timestamp.fromDate(start);
      const endTimestamp = Timestamp.fromDate(end);

      console.log(`Fetching data for month: ${start} to ${end}`);

      const clockInOutsQuery = query(
        collection(db, 'clockInOuts'),
        where('UserID', '==', employeeId),
        where('Clockin', '>=', startTimestamp),
        where('Clockin', '<=', endTimestamp)
      );

      const clockInOutsSnapshot = await getDocs(clockInOutsQuery);
      console.log(`ClockInOutsSnapshot size: ${clockInOutsSnapshot.size}`);

      const clockInOutData = clockInOutsSnapshot.docs.map(doc => {
        const docData = doc.data();
        console.log(`Fetched clock-in data:`, docData);
        return {
          ...docData,
          clockin: docData.Clockin.toDate(),
          clockout: docData.Clockout ? docData.Clockout.toDate() : null,
          id: doc.id
        };
      });

      if (clockInOutData.length === 0) return; // Skip calculations if no clock-in records for the month

      const binTagScansQuery = query(
        collection(db, 'bintagscans'),
        where('logID', 'in', clockInOutData.map(d => d.id))
      );

      const binTagScansSnapshot = await getDocs(binTagScansQuery);
      const scannedBinTagsSet = new Set(binTagScansSnapshot.docs.map(doc => doc.data().scanResult));

      const violationsQuery = query(
        collection(db, 'violations'),
        where('EmployeeID', '==', employeeId),
        where('createdAt', '>=', startTimestamp),
        where('createdAt', '<=', endTimestamp)
      );

      const violationsSnapshot = await getDocs(violationsQuery);
      const totalViolationsCount = violationsSnapshot.size;
      const takenViolationsCount = violationsSnapshot.docs.filter(doc => clockInOutData.map(d => d.id).includes(doc.data().logId)).length;

      const totalBinTagsCount = await calculateTotalBinTags(clockInOutData);

      setMonthlyData(clockInOutData);
      const missedDays = calculateMissedDays(clockInOutData, start, end);
      setDaysMissed(missedDays);
      setScannedBinTags(scannedBinTagsSet.size);
      setTotalBinTags(totalBinTagsCount);
      setTotalViolations(totalViolationsCount);
      setTakenViolations(takenViolationsCount);
    } catch (error) {
      console.error("Error fetching data:", error);
      logError(error);
    }
  };

  const calculateTotalBinTags = async (clockInOutData) => {
    let totalBinTags = 0;

    for (const data of clockInOutData) {
      const propertyRef = doc(db, 'communities', data['Property Name']);
      const propertyDoc = await getDoc(propertyRef);
      if (propertyDoc.exists()) {
        const propertyData = propertyDoc.data();
        console.log(`Fetched property data:`, propertyData);
        const binTagsSnapshot = await getDocs(collection(db, `communities/${propertyData.propertyName}/bintags`));
        totalBinTags += binTagsSnapshot.docs.length;
      }
    }

    return totalBinTags;
  };

  const calculateMissedDays = (clockInOutData, start, end) => {
    const daysInMonth = Array.from({ length: end.getDate() }, (_, i) => new Date(start.getFullYear(), start.getMonth(), i + 1));
    const workedDays = clockInOutData.map(data => format(data.clockin, 'yyyy-MM-dd'));
    const missedDays = daysInMonth.filter(date => {
      const dayOfWeek = date.getDay();
      const dateString = format(date, 'yyyy-MM-dd');
      return dayOfWeek >= 0 && dayOfWeek <= 4 && !workedDays.includes(dateString); // Monday to Thursday
    });
    return missedDays.length;
  };

  const logError = async (error) => {
    try {
      await addDoc(collection(db, 'logs'), {
        action: 'Fetch Monthly Data',
        category: 'Bonus Report',
        error: error.message,
        timestamp: new Date()
      });
      const logsSnapshot = await getDocs(collection(db, 'logs'));
      setLogs(logsSnapshot.docs.map(doc => doc.data()));
    } catch (err) {
      console.error("Error logging error:", err);
    }
  };

  const resetState = () => {
    setMonthlyData([]);
    setLogs([]);
    setTotalBinTags(0);
    setScannedBinTags(0);
    setTotalViolations(0);
    setTakenViolations(0);
    setDaysMissed(0);
  };

  const onDateClick = useCallback((date) => {
    setSelectedDate(date);
    const details = monthlyData.filter(data => format(data.clockin, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));
    setSelectedDateDetails(details);
  }, [monthlyData]);

  const renderCalendarTile = ({ date, view }) => {
    if (view === 'month') {
      const dayOfWeek = date.getDay();
      if (dayOfWeek === 5 || dayOfWeek === 6) {
        return null; // No color for Fridays and Saturdays
      }
      const dataForDate = monthlyData.find(data => format(data.clockin, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));
      const color = dataForDate ? 'green' : 'red';
      return <div style={{ backgroundColor: color, borderRadius: '50%', width: '10px', height: '10px', margin: 'auto' }} />;
    }
  };

  const renderDetails = () => {
    if (!selectedDateDetails) return null;
    return selectedDateDetails.map(detail => (
      <div key={detail.id} className="detail">
        <p>Property: {detail['Property Name']}</p>
        <p>BinTag %: {detail.binTagScannedPercent !== undefined ? detail.binTagScannedPercent.toFixed(2) : '0'}%</p>
        <p>Violations: {detail.actualViolations !== undefined ? detail.actualViolations : '0'} / {takenViolations}</p>
        <p>Clock-in: {format(detail.clockin, 'hh:mm a')}</p>
        <p>Clock-out: {detail.clockout ? format(detail.clockout, 'hh:mm a') : 'N/A'}</p>
      </div>
    ));
  };

  const renderBonusStatus = (percentage) => (
    <div className="circle-chart">
      <svg viewBox="0 0 36 36" className="circular-chart green">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${percentage}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" className="percentage">{`${percentage.toFixed(2)}%`}</text>
      </svg>
    </div>
  );

  const binTagPercentage = totalBinTags > 0 ? (scannedBinTags / totalBinTags) * 100 : 0;
  const violationPercentage = takenViolations > 0 ? (totalViolations / takenViolations) * 100 : 0;

  return (
    <div className="bonus-report">
      <div className="calendar-section">
        <Calendar
          value={currentMonth}
          onChange={setCurrentMonth}
          maxDate={new Date()}
          tileContent={renderCalendarTile}
          onClickDay={onDateClick}
        />
        <div className="details">
          {renderDetails()}
        </div>
      </div>
      <div className="stats-section">
        <h2 className="report-title">Bonus Report for {format(currentMonth, 'MMMM yyyy')}</h2>
        <div className="bonus-status">
          <div className="status-item">
            <h3>BinTag Completion</h3>
            {renderBonusStatus(binTagPercentage)}
          </div>
          <div className="status-item">
            <h3>Violation Completion</h3>
            {renderBonusStatus(violationPercentage)}
          </div>
          <div className="status-item">
            <h3>On Track for Bonus</h3>
            <p className={((scannedBinTags / totalBinTags) >= 0.9 && (totalViolations <= takenViolations)) ? 'on-track' : 'not-on-track'}>
              {((scannedBinTags / totalBinTags) >= 0.9 && (totalViolations <= takenViolations)) ? 'Yes' : 'No'}
            </p>
          </div>
          <div className="status-item">
            <h3>Days Missed</h3>
            <p>{daysMissed}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusReport;
