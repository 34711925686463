import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/greenlogo.svg';
import './WebNav.css';

const WebNav = () => {
  return (
    <div className="web-nav">
      <NavLink to="/logs" className={({ isActive }) => (isActive ? 'active' : '')}>
        Logs
      </NavLink>
      <NavLink to="/" className="logo-link">
        <img src={Logo} alt="Home" className="logo-employee" />
      </NavLink>
      <NavLink to="/profile" className={({ isActive }) => (isActive ? 'active' : '')}>
        Profile
      </NavLink>
    </div>
  );
};

export default WebNav;
