import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Modal from '../../../components/Modal';
import { Box, Typography, Button } from '@mui/material';

const BinTagScanner = ({ activeClockInId, onClose }) => {
    const [scannedTags, setScannedTags] = useState(0);
    const [totalBinTags, setTotalBinTags] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBinTags = async () => {
            try {
                const binTagsSnapshot = await getDocs(collection(db, 'communities', 'bens-house', 'bintags'));
                const binTagsData = binTagsSnapshot.docs.map(doc => doc.data());
                setTotalBinTags(binTagsData.length);
            } catch (err) {
                setError('Failed to fetch bin tags. Please try again.');
            }
        };

        const fetchScannedBinTags = async () => {
            try {
                const q = query(collection(db, 'bintagscans'), where('logID', '==', activeClockInId));
                const scannedBinTagsSnapshot = await getDocs(q);
                const uniqueScannedTags = new Set(scannedBinTagsSnapshot.docs.map(doc => doc.data().scanResult));
                setScannedTags(uniqueScannedTags.size);
            } catch (err) {
                setError('Failed to fetch scanned bin tags. Please try again.');
            }
        };

        fetchBinTags();
        fetchScannedBinTags();
    }, [activeClockInId]);

    return (
        <Modal isOpen={true} onClose={onClose}>
            <Box p={3}>
                <Typography variant="h6">Bin Tag Scanner Instructions</Typography>
                <Typography variant="body1">
                    This is a placeholder for the instructions on how to scan bin tags. Please ensure each bin tag is scanned once per shift.
                </Typography>
                <Typography variant="body2" mt={2}>
                    Scanned Bin Tags: {scannedTags}/{totalBinTags}
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button variant="contained" onClick={onClose}>Close</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default BinTagScanner;
