import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataGrid';
import Modal from '../../components/Modal';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { format } from 'date-fns';
import ImageIcon from '@mui/icons-material/Image';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { green, red } from '@mui/material/colors';

// Define the columns for the MUI DataGrid
const columns = [
  { field: 'employee', headerName: 'Employee', flex: 1 },
  { field: 'property', headerName: 'Property', flex: 1 },
  { field: 'unitNumber', headerName: 'Unit Number', flex: 1 },
  { field: 'collected', headerName: 'Collected', flex: 1, renderCell: (params) => (
      <Chip
        label={params.value ? 'True' : 'False'}
        style={{ backgroundColor: params.value ? green[500] : red[500], color: 'white' }}
      />
    )
  },
  {
    field: 'image',
    headerName: 'Image',
    flex: 1,
    renderCell: (params) => (
      params.value ? (
        <Tooltip title="View Image">
          <ImageIcon onClick={() => params.row.showImage(params.value)} style={{ cursor: 'pointer', color: green[500] }} />
        </Tooltip>
      ) : (
        <ImageIcon style={{ color: red[500] }} />
      )
    )
  },
  { field: 'createdAt', headerName: 'Date', flex: 1 }
];

const dateFilterOptions = [
  { label: "Created Date", value: "createdAt" }
];

const ViolationsPage = () => {
  const [rows, setRows] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const violationsSnapshot = await getDocs(collection(db, 'violations'));
      const usersSnapshot = await getDocs(collection(db, 'team'));

      const userMap = usersSnapshot.docs.reduce((acc, doc) => {
        const userData = doc.data();
        acc[doc.id] = `${userData.firstName} ${userData.lastName}`;
        return acc;
      }, {});

      const allData = violationsSnapshot.docs.map(doc => {
        const docData = doc.data();
        return {
          id: doc.id,
          employee: userMap[docData.EmployeeID] || '',
          property: docData.Property || '',
          unitNumber: docData.UnitNumber || '',
          collected: docData.Collected || false,
          image: docData.image || '',
          createdAt: docData.createdAt ? format(docData.createdAt.toDate(), 'MM/dd/yyyy hh:mm a') : '',
          showImage: handleShowImage
        };
      });
      setRows(allData);
    };

    fetchData();
  }, []);

  const handleShowImage = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setShowImageModal(true);
  };

  return (
    <div>
      <DataTable 
        rows={rows} 
        columns={columns} 
        dateFilterOptions={dateFilterOptions}
      />
      <Modal isOpen={showImageModal} onClose={() => setShowImageModal(false)}>
        <img src={currentImageUrl} alt="Violation" style={{ width: '100%' }} />
      </Modal>
    </div>
  );
};

export default ViolationsPage;
