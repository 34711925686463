import React, { useState, useRef, useEffect, useCallback } from 'react';
import { storage, db } from '../../../firebase'; // Ensure these are correctly imported
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const TrashCompactorPhotos = ({ userId, currentPhotoStage, onClose, activeClockInId, setIsCompactorCompleted }) => {
    const [photoStage, setPhotoStage] = useState(currentPhotoStage || 'before');
    const [photo, setPhoto] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [camera, setCamera] = useState('environment');

    useEffect(() => {
        const checkPhotoStage = async () => {
            if (activeClockInId) {
                const clockInRef = doc(db, 'clockInOuts', activeClockInId);
                const docSnap = await getDoc(clockInRef);

                if (docSnap.exists() && docSnap.data().beforeTrashCompactor && docSnap.data().afterTrashCompactor) {
                    setIsCompactorCompleted(true);
                    onClose();
                } else if (docSnap.exists() && docSnap.data().beforeTrashCompactor) {
                    setPhotoStage('after');
                }
            }
        };

        checkPhotoStage();
    }, [activeClockInId, onClose, setIsCompactorCompleted]);

    const startVideo = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: camera } });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play();
                    videoRef.current.playbackRate = 1;
                };
            }
        } catch (error) {
            console.error('Error accessing the camera:', error);
        }
    }, [camera]);

    useEffect(() => {
        startVideo();

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
        };
    }, [startVideo]);

    const handleCapture = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const photoData = canvas.toDataURL('image/png');
        setPhoto(photoData);
    };

    const handleAccept = async () => {
        if (!activeClockInId) {
            console.error('Active clock-in ID is not defined');
            return;
        }

        const imageBlob = await fetch(photo).then(res => res.blob());
        const imageName = `${userId}_${photoStage}_trashCompactor_${new Date().toISOString()}.png`;
        const imageRef = ref(storage, `compactorImages/${imageName}`);

        try {
            await uploadBytes(imageRef, imageBlob);
            const imageUrl = await getDownloadURL(imageRef);
            const clockInRef = doc(db, 'clockInOuts', activeClockInId);
            const fieldToUpdate = photoStage === 'before' ? 'beforeTrashCompactor' : 'afterTrashCompactor';

            await updateDoc(clockInRef, {
                [fieldToUpdate]: {
                    url: imageUrl,
                    timestamp: new Date()
                }
            });

            if (photoStage === 'before') {
                setPhotoStage('after');
                setPhoto(null);
            } else {
                setIsCompactorCompleted(true);
                onClose();
            }
        } catch (error) {
            console.error('Failed to upload image or update Firestore:', error);
        }
    };

    const handleReject = () => {
        setPhoto(null);
    };

    return (
        <div className="trash-compactor-photos">
            <p>Take a {photoStage} photo of the trash compactor.</p>
            {photo ? (
                <div className="photo-preview">
                    <img src={photo} alt={`${photoStage} photo`} />
                    <div className="button-container">
                        <button className="save-button" onClick={handleAccept}>Accept</button>
                        <button className="delete-button" onClick={handleReject}>Retake</button>
                    </div>
                </div>
            ) : (
                <div className="camera-view">
                    <video ref={videoRef} width="100%" height="100%" autoPlay playsInline className="video-feed" />
                    <canvas ref={canvasRef} width="100%" height="100%" className="hidden-canvas"></canvas>
                    <button className="save-button" onClick={handleCapture}>Capture</button>
                    <select className="drop-down" value={camera} onChange={e => setCamera(e.target.value)}>
                        <option value="environment">Rear Camera</option>
                        <option value="user">Front Camera</option>
                    </select>
                </div>
            )}
        </div>
    );
};

export default TrashCompactorPhotos;
