import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { deleteUser, reauthenticateWithCredential, EmailAuthProvider, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { doc, deleteDoc } from 'firebase/firestore';
import ChangePasswordModal from './portal/components/ChangePassword';
import './Settings.css';

const SettingsPage = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReauthOpen, setIsReauthOpen] = useState(false);
  const [userProvider, setUserProvider] = useState('');
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user && user.providerData.length > 0) {
      setUserProvider(user.providerData[0].providerId);
    }
  }, []);

  const reauthenticate = async () => {
    try {
      const user = auth.currentUser;
      if (userProvider === 'google.com') {
        const googleProvider = new GoogleAuthProvider();
        await signInWithPopup(auth, googleProvider);
      } else {
        const credential = EmailAuthProvider.credential(user.email, 'current-password-placeholder'); // Need to replace with actual current password input
        await reauthenticateWithCredential(user, credential);
      }
    } catch (error) {
      console.error('Reauthentication error:', error);
      setErrorMessage(error.message);
    }
  };

  const handleDeleteAccount = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    try {
      await reauthenticate();
      const user = auth.currentUser;
      await deleteDoc(doc(db, 'team', user.uid));
      await deleteUser(user);
      alert('Account deleted successfully.');
      signOut(auth);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="settings-container">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="settings-form">
        <div className="form-group">
          <button className="save-button" onClick={() => setIsChangePasswordModalOpen(true)}>Change Password</button>
        </div>
        <div className="form-group">
          {confirmDelete ? (
            <>
              <p>Are you sure you want to delete your account? This action is irreversible.</p>
              <button onClick={handleDeleteAccount}>Confirm Delete Account</button>
            </>
          ) : (
            <button className="delete-button" onClick={() => setConfirmDelete(true)}>Delete Account</button>
          )}
        </div>
      </div>
      {isChangePasswordModalOpen && (
        <ChangePasswordModal onClose={() => setIsChangePasswordModalOpen(false)} />
      )}
    </div>
  );
};

export default SettingsPage;
