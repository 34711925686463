import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Modal from '../../../components/Modal';
import { db } from '../../../firebase';
import './Profile.css';
import defaultProfilePic from '../../../assets/default.png';
import ChangePasswordModal from '../components/ChangePassword';
import Loader from '../../../components/Loader'; // Import the Loader component

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [joinDate, setJoinDate] = useState('');
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false); // New state for password modal
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const userDoc = await getDoc(doc(db, 'team', currentUser.uid));
                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                    calculateJoinDate(userDoc.data().startDate);
                }
            }
            setLoading(false); // Set loading to false after authentication state is checked
        });

        return () => unsubscribe();
    }, []);

    const calculateJoinDate = (startDate) => {
        const joinDate = new Date(startDate);
        const currentDate = new Date();
        const differenceInTime = currentDate - joinDate;
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        setJoinDate(`${differenceInDays} days ago`);
    };

    const handleEditProfile = () => {
        setIsUserModalOpen(true);
    };

    const handleImportantLinks = () => {
        setIsLinksModalOpen(true);
    };

    const handleChangePassword = () => {
        setIsPasswordModalOpen(true); // Show password modal
    };

    const handleLogout = async () => {
        const auth = getAuth();
        await signOut(auth);
        navigate('/employee-dashboard');
    };

    if (loading) {
        return <Loader />; // Use the Loader component
    }

    return (
        <div className="employee-container">
            <div className="profile-container">
                {userData && (
                    <>
                        <div className="profile-header">
                            <img src={userData.profilePic || defaultProfilePic} alt="Profile" className="profile-pic" />
                            <div className="profile-info">
                                <h1>{userData.firstName} {userData.lastName}</h1>
                                <p>Joined {joinDate}</p>
                            </div>
                        </div>
                        <div className="profile-links">
                            <h2>Profile</h2>
                            <div className="profile-link" onClick={handleEditProfile}>
                                <p>Customize Profile</p>
                            </div>
                            <h2>Settings</h2>
                            <div className="profile-link" onClick={handleImportantLinks}>
                                <p>Important Links</p>
                            </div>
                            <div className="profile-link" onClick={handleChangePassword}>
                                <p>Change Password</p>
                            </div>
                            <div className="profile-link" onClick={handleLogout}>
                                <p>Sign Out</p>
                            </div>
                        </div>

                        {isUserModalOpen && (
                            <Modal isOpen={isUserModalOpen} onClose={() => setIsUserModalOpen(false)}>
                                <UserModal userData={userData} setUserData={setUserData} onClose={() => setIsUserModalOpen(false)} />
                            </Modal>
                        )}

                        {isLinksModalOpen && (
                            <Modal isOpen={isLinksModalOpen} onClose={() => setIsLinksModalOpen(false)}>
                                <LinksModal onClose={() => setIsLinksModalOpen(false)} />
                            </Modal>
                        )}

                        {isPasswordModalOpen && (
                            <Modal isOpen={isPasswordModalOpen} onClose={() => setIsPasswordModalOpen(false)}>
                                <ChangePasswordModal onClose={() => setIsPasswordModalOpen(false)} />
                            </Modal>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Profile;

// The UserModal and LinksModal components remain unchanged
const UserModal = ({ userData, setUserData, onClose }) => {
    const [email, setEmail] = useState(userData.email);
    const [phone, setPhone] = useState(userData.phone);
    const [profilePic, setProfilePic] = useState(userData.profilePic);
    const [newProfilePic, setNewProfilePic] = useState(null);
    const auth = getAuth();
    const currentUser = auth.currentUser;

    const handleSave = async () => {
        if (currentUser) {
            let profilePicUrl = profilePic;

            if (newProfilePic) {
                const storage = getStorage();
                const storageRef = ref(storage, `profile-pics/${currentUser.uid}`);
                await uploadBytes(storageRef, newProfilePic);
                profilePicUrl = await getDownloadURL(storageRef);
            }

            const userRef = doc(db, 'team', currentUser.uid);
            await updateDoc(userRef, {
                email,
                phone,
                profilePic: profilePicUrl
            });

            // Update the userData state in Profile component
            setUserData(prevData => ({
                ...prevData,
                email,
                phone,
                profilePic: profilePicUrl
            }));

            onClose();
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setNewProfilePic(e.target.files[0]);
        }
    };

    return (
        <div className="modal-form">
            <label>
                Email:
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
                Phone:
                <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </label>
            <label>
                Profile Picture:
                <input type="file" accept="image/*" onChange={handleFileChange} />
            </label>
            <button className="save-button" onClick={handleSave}>Save</button>
        </div>
    );
};

const LinksModal = ({ onClose }) => {
    return (
        <div className="links-modal">
            <div className="link-item" onClick={() => window.location.href = 'https://wastefree.rockstarlearning.com/'}>
                Training Videos
            </div>
            <div className="link-item" onClick={() => window.location.href = 'https://www.cornerstonepeo.com/'}>
                Professional Employer Hub
            </div>
        </div>
    );
};
