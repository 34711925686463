import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../firebase';
import Modal from '../components/Modal';
import defaultProfilePic from '../assets/default.png';
import './Team.css';

const Team = () => {
  const navigate = useNavigate();
  const [savedMembers, setSavedMembers] = useState(new Set());
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [tier, setTier] = useState('');
  const [startDate, setStartDate] = useState('');
  const [phone, setPhone] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSavedMembers = async () => {
      const savedMembersSet = new Set();
      const teamCollectionSnapshot = await getDocs(collection(db, 'team'));
      teamCollectionSnapshot.forEach(doc => {
        const data = doc.data();
        const fullName = `${data.firstName} ${data.lastName}`;
        if (fullName) savedMembersSet.add({ fullName, profilePic: data.profilePic || defaultProfilePic });
      });
      setSavedMembers(savedMembersSet);
    };

    fetchSavedMembers();
  }, []);

  const handleMemberClick = async (member) => {
    setCurrentMember(member);
    const [firstName, ...lastNameParts] = member.fullName.split(' ');
    setFirstName(firstName);
    setLastName(lastNameParts.join(' '));
    if (savedMembers.has(member)) {
      const teamCollectionSnapshot = await getDocs(collection(db, 'team'));
      const userDoc = teamCollectionSnapshot.docs.find(doc => {
        const data = doc.data();
        return data.firstName === firstName && data.lastName === lastNameParts.join(' ');
      });

      if (userDoc) {
        navigate(`/admin/team/${userDoc.id}`);
      }
    } else {
      setModalOpen(true);
    }
  };

  const handleAddMember = () => {
    setCurrentMember(null);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setTier('');
    setStartDate('');
    setModalOpen(true);
  };

  const handleSaveMember = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    const temporaryPassword = Math.random().toString(36).slice(-8);
    
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, temporaryPassword);
      const user = userCredential.user;
      console.log('User created:', user);

      const memberData = {
        firstName,
        lastName,
        email,
        phone,
        tier,
        startDate: startDate || null,
        profilePic: defaultProfilePic,
        temporaryPassword
      };
      await setDoc(doc(db, "team", user.uid), memberData);
      setSavedMembers(new Set([...savedMembers, { fullName: `${firstName} ${lastName}`, profilePic: defaultProfilePic }]));
      setModalOpen(false);
      navigate(`/admin/team/${user.uid}`);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search for team members..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <button onClick={handleAddMember} className="save-button">Add Team Member</button>
      </div>
      <div className="community-container">
        {[...savedMembers].filter(member => member.fullName.toLowerCase().includes(searchTerm.toLowerCase())).map((member, index) => (
          <div key={index} onClick={() => handleMemberClick(member)} className="team-member-card">
            <img src={member.profilePic} alt="Profile" className="profile-pic-grid" />
            <span>{member.fullName}</span>
          </div>
        ))}
      </div>
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <form onSubmit={handleSaveMember} className="modal-form">
            <label>
              First Name:
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
            </label>
            <label>
              Last Name:
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
            </label>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={!isEmailValid(email) ? 'invalid' : ''}
              />
              {!isEmailValid(email) && <span className="error">Invalid email address</span>}
            </label>
            <label>
              Phone:
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </label>
            <label>
              Tier:
              <select value={tier} onChange={(e) => setTier(e.target.value)} required>
                <option value="">Select Tier</option>
                <option value="Collection Specialist">Collection Specialist</option>
                <option value="District Manager">District Manager</option>
                <option value="Admin">Admin</option>
              </select>
            </label>
            <label>
              Start Date:
              <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </label>
            <button type="submit" disabled={!isEmailValid(email)}>Save</button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Team;
