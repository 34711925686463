import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/greenlogo.svg';
import './portal/EmployeeLogin.css';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const auth = getAuth();

    const handleEmailPasswordLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/admin');
        } catch (error) {
            setError('Failed to log in. Please check your email and password.');
        }
    };

    return (
        <div className="auth-container">
            <div className="login-side">
                <div className="login-card">
                    <img src={Logo} alt="iconLogo" className="logo" />
                    <h1 className="login-title">WasteFree Admin Portal</h1>
                    <form onSubmit={handleEmailPasswordLogin}>
                        <label className="auth-label">Email</label>
                        <input
                            className="input-login"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label className="auth-label">Password</label>
                        <input
                            className="input-login"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        {error && <p className="error-message">{error}</p>}
                        <button type="submit" className="save-button">Login</button>
                    </form>
                </div>
            </div>
            <div className="artwork-section">
                {/* Artwork */}
            </div>
        </div>
    );
};

export default AdminLogin;
