import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useCookies } from 'react-cookie';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import Login from './pages/Login';
import EmployeeLogin from './pages/portal/EmployeeLogin';
import HomePage from './pages/Home';
import Dashboard from './pages/Dashboard';
import EmployeeHub from './pages/portal/EmployeeHub';
import DataInputPage from './pages/Data';
import ClockInOuts from './pages/masters/ClockInOuts';
import ViolationsPage from './pages/masters/ViolationsPage';
import ServicesPage from './pages/masters/Service';
import UnitsPage from './pages/masters/Units';
import StatusPage from './pages/StatusPage';
import Community from './pages/Community';
import Team from './pages/Team';
import PropertyDetailsPage from './pages/PropertyDetailsPage';
import EmployeeDetailsPage from './pages/TeamProfile';
import Settings from './pages/Settings';
import AllBinTags from './pages/AllBinTags';
import DailyReports from './pages/masters/DailyReport';
import CommunityPublic from './pages/community/CommunityPublic';
import CommunityAdmin from './pages/community/CommunityAdmin';
import BintagRedirect from './hooks/BintagRedirect';
import Header from './pages/community/HeaderPublic';

function App() {
  const [cookies, setCookie] = useCookies(['user', 'employee']);
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isSuperAdmin = user.email === 'bperalta@wastefree.com';
        if (isSuperAdmin) {
          setCookie('user', user, { path: '/' });
        } else {
          const userDoc = await getDoc(doc(db, 'team', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.tier === 'Admin') {
              setCookie('user', user, { path: '/' });
            }
            setCookie('employee', user, { path: '/' });
          } else {
            setCookie('employee', user, { path: '/' });
          }
        }
      } else {
        setCookie('user', null, { path: '/' });
        setCookie('employee', null, { path: '/' });
      }
    });
  }, [auth, setCookie]);

  return (
    <Router>
      <Routes>
        {/* Employee routes */}
        <Route path="/employee-login" element={cookies.employee || cookies.user ? <Navigate to="/" /> : <EmployeeLogin />} />
        <Route path="/*" element={cookies.employee || cookies.user ? <EmployeeHub /> : <Navigate to="/employee-login" />} />

        {/* Admin routes */}
        <Route path="/admin/login" element={cookies.user ? <Navigate to="/admin" /> : <Login />} />
        <Route path="/admin" element={cookies.user ? <Dashboard /> : <Navigate to="/admin/login" />}>
          <Route index element={<HomePage />} />
          <Route path="data-input" element={<StatusPage />} />
          <Route path="clock-in-outs" element={<ClockInOuts />} />
          <Route path="violations" element={<ViolationsPage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="units" element={<UnitsPage />} />
          <Route path="daily-report" element={<DailyReports />} />
          <Route path="communities" element={<Community />} />
          <Route path="properties/:propertyName" element={<PropertyDetailsPage />} />
          <Route path="properties/:propertyName/bintags" element={<AllBinTags />} />
          <Route path="team" element={<Team />} />
          <Route path="team/:employeeId" element={<EmployeeDetailsPage />} />
          <Route path="settings" element={<Settings />} />
        </Route>

        {/* Community routes */}
        <Route path="/community" element={<><Header /><Outlet /></>}>
          <Route path=":propertyName" element={<CommunityPublic />} />
          <Route path=":propertyName/bintag/:bintagId" element={<BintagRedirect />} />
          <Route path=":propertyName/admin" element={<CommunityAdmin />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
