import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import defaultProfilePic from '../../../assets/default.png';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import BonusReport from '../components/BonusReport';
import Loader from '../../../components/Loader'; // Import the Loader component
import './Profile.css';

const EmployeeStatus = () => {
    const { employeeId } = useParams();
    const [employeeData, setEmployeeData] = useState(null);
    const [daysSinceJoined, setDaysSinceJoined] = useState('');
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        const fetchEmployeeData = async () => {
            const employeeDoc = await getDoc(doc(db, 'team', employeeId));
            if (employeeDoc.exists()) {
                const data = employeeDoc.data();
                setEmployeeData(data);
                calculateDaysSinceJoined(data.startDate);
            }
        };

        const fetchClockInData = async () => {
            const q = query(collection(db, 'clockInOuts'), where('UserID', '==', employeeId));
            const querySnapshot = await getDocs(q);
            const propertyNames = querySnapshot.docs.map(doc => doc.data()['Property Name']);
            const uniqueProperties = [...new Set(propertyNames)];
            setProperties(uniqueProperties);
        };

        fetchEmployeeData();
        fetchClockInData();
    }, [employeeId]);

    const calculateDaysSinceJoined = (startDate) => {
        const joinDate = new Date(startDate);
        const currentDate = new Date();
        const differenceInTime = currentDate - joinDate;
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        setDaysSinceJoined(`${differenceInDays} days ago`);
    };

    if (!employeeData) {
        return <Loader />; // Use the Loader component
    }

    return (
        <div className="employee-container">
            <div className="profile-container">
                <div className="profile-header">
                    <img src={employeeData.profilePic || defaultProfilePic} alt="Profile" className="profile-pic-profile" />
                    <div className="profile-info">
                        <h1>{employeeData.firstName} {employeeData.lastName}</h1>
                        <p>{employeeData.bio}</p>
                        <p>Joined {daysSinceJoined}</p>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                            {properties.map((property, index) => (
                                <Chip key={index} label={property} />
                            ))}
                        </Box>
                    </div>
                </div>
            </div>
            <BonusReport employeeId={employeeId} />
        </div>
    );
};

export default EmployeeStatus;
