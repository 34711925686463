import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { collection, getDocs, doc, getDoc, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import AnimatedTabs from '../design/AnimatedTabs';
import './StatusPage.css';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2FzdGVmcmVlIiwiYSI6ImNseGwybWpudTA3ZnUya3B0aXl1aTBzYXEifQ.OX6sLd_gwW6eE7FPNR_OOw';

const StatusPage = () => {
  const [properties, setProperties] = useState([]);
  const [clockIns, setClockIns] = useState([]);
  const [pendingProperties, setPendingProperties] = useState([]);
  const [employees, setEmployees] = useState({});
  const [activeTab, setActiveTab] = useState('Pending');
  const [currentClockInIndex, setCurrentClockInIndex] = useState(0); // For toggling between multiple clock-ins
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const markersRef = useRef([]);

  useEffect(() => {
    if (mapRef.current) return; // Map already initialized

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-96.796989, 32.776665], // Center the map here
      zoom: 10
    });
    mapRef.current = map;

    map.on('load', () => {
      console.log('Map loaded');
      fetchProperties();
      fetchClockIns();
    });

    map.on('moveend', updateMarkers);
  }, []);

  const fetchProperties = async () => {
    try {
      const propertiesSnapshot = await getDocs(collection(db, 'communities'));
      const propertiesData = propertiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProperties(propertiesData);
      console.log('Fetched properties:', propertiesData);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  const fetchClockIns = async () => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Start of the day
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1); // Start of the next day

      console.log('Fetching clock-ins between:', today.toISOString(), 'and', tomorrow.toISOString());

      const clockInsQuery = query(collection(db, 'clockInOuts'), where('Clockin', '>=', Timestamp.fromDate(today)), where('Clockin', '<', Timestamp.fromDate(tomorrow)));
      const clockInsSnapshot = await getDocs(clockInsQuery);
      const clockInsData = clockInsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setClockIns(clockInsData);
      console.log('Fetched clock-ins:', clockInsData);

      // Log each clock-in and clock-out separately
      clockInsData.forEach(clockIn => {
        console.log(`Clockin: ${clockIn.Clockin.toDate()}`);
        console.log(`Clockout: ${clockIn.Clockout ? clockIn.Clockout.toDate() : 'null'}`);
      });

      // Fetch employee data for clock-ins
      const employeeIds = new Set(clockInsData.map(clockIn => clockIn.UserID));
      const employeeData = {};
      for (const userId of employeeIds) {
        const userDoc = await getDoc(doc(db, 'team', userId));
        if (userDoc.exists()) {
          employeeData[userId] = userDoc.data();
          console.log(`Fetched employee ${userId}:`, userDoc.data());
        } else {
          console.error(`User not found: ${userId}`);
        }
      }
      setEmployees(employeeData);
      console.log('Fetched employees:', employeeData);
    } catch (error) {
      console.error('Error fetching clock-ins:', error);
    }
  };

  const normalizePropertyName = (name) => {
    return name.replace(/\W+/g, ' ').trim().toLowerCase();
  };

  const updateMarkers = () => {
    if (!mapRef.current || properties.length === 0) return;

    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of today in user's local time
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    properties.forEach(property => {
      let statusColor = 'red'; // Default to red
      let clockInData = null;

      const propertyClockIns = clockIns.filter(clockIn => {
        const clockInTime = clockIn.Clockin.toDate();
        const isSameProperty = normalizePropertyName(clockIn['Property Name']) === normalizePropertyName(property.propertyName);
        const isInDateRange = clockInTime >= today && clockInTime < tomorrow;
        return isSameProperty && isInDateRange;
      });

      if (propertyClockIns.length > 0) {
        const activeClockIn = propertyClockIns.find(clockIn => !clockIn.Clockout);
        statusColor = activeClockIn ? 'orange' : 'green';
        clockInData = activeClockIn || propertyClockIns[currentClockInIndex];
      }

      const marker = new mapboxgl.Marker({ color: statusColor })
        .setLngLat([property.longitude, property.latitude])
        .addTo(mapRef.current);

      marker.getElement().style.cursor = 'pointer';

      marker.getElement().addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent map click events from firing

        // Handle multiple clock-in sessions
        let currentClockInIndex = 0;
        const propertyClockInsLength = propertyClockIns.length;

        const updatePopupContent = () => {
          const clockInData = propertyClockIns[currentClockInIndex];
          const employee = clockInData ? employees[clockInData.UserID] : null;
          const popupContent = `
            <h3>${property.propertyName}</h3>
            <p><strong>Clocked In:</strong> ${clockInData ? clockInData.Clockin.toDate().toLocaleString() : 'N/A'}</p>
            <p><strong>Clocked Out:</strong> ${clockInData && clockInData.Clockout ? clockInData.Clockout.toDate().toLocaleString() : 'N/A'}</p>
            <p><strong>Employee:</strong> ${employee ? `${employee.firstName} ${employee.lastName}` : 'N/A'}</p>
            <p><strong>Session:</strong> ${currentClockInIndex + 1} of ${propertyClockInsLength}</p>
            <button id="prev-session">Previous</button>
            <button id="next-session">Next</button>
          `;
          const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: true })
            .setLngLat([property.longitude, property.latitude])
            .setHTML(popupContent)
            .addTo(mapRef.current);

          popup.getElement().querySelector('#prev-session').addEventListener('click', (event) => {
            event.stopPropagation();
            currentClockInIndex = (currentClockInIndex - 1 + propertyClockInsLength) % propertyClockInsLength;
            updatePopupContent();
          });

          popup.getElement().querySelector('#next-session').addEventListener('click', (event) => {
            event.stopPropagation();
            currentClockInIndex = (currentClockInIndex + 1) % propertyClockInsLength;
            updatePopupContent();
          });

          mapRef.current.flyTo({ center: [property.longitude, property.latitude], zoom: 15 });
        };

        updatePopupContent();
      });

      markersRef.current.push(marker);
    });
  };


  const getDuration = (clockIn, clockOut) => {
    const startTime = clockIn.toDate();
    const endTime = clockOut.toDate();
    const diff = Math.floor((endTime - startTime) / 1000);
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = diff % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    if (properties.length > 0) {
      console.log('Properties changed, updating markers...');
      updateMarkers();
    }
  }, [properties, clockIns, employees]); // Added employees to the dependency array

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePropertyClick = (property) => {
    if (mapRef.current) {
      mapRef.current.flyTo({ center: [property.longitude, property.latitude], zoom: 15 });
    }
  };

  const getPropertyColor = (property) => {
    if (pendingProperties.includes(property)) {
      return 'red'; // Pending property
    }
    const formattedPropertyName = normalizePropertyName(property.propertyName);
    const clockInForProperty = clockIns.find(clockIn => normalizePropertyName(clockIn['Property Name']) === formattedPropertyName);
    if (clockInForProperty) {
      return clockInForProperty.Clockout ? 'green' : 'orange';
    }
    return 'red'; // Default to red if no clock-in found
  };

  const filteredProperties = activeTab === 'Pending'
    ? properties.filter(property => getPropertyColor(property) === 'red')
    : properties.filter(property => getPropertyColor(property) !== 'red');

  return (
    <div className="status-page">
      <div ref={mapContainerRef} className="map-container"></div>
      <div className="status-report">
        <AnimatedTabs onTabChange={handleTabChange} />
        <ul>
          {filteredProperties.map(property => (
            <li
              key={property.id}
              onClick={() => handlePropertyClick(property)}
              style={{ color: getPropertyColor(property) }}
            >
              {property.propertyName}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StatusPage;
