import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import EmployeeProfile from './team/EmployeeProfile';
import BonusReport from './portal/components/BonusReport';
import { db } from '../firebase';
import './TeamProfile.css';
import Loader from '../components/Loader'; 

const TeamProfile = () => {
    const { employeeId } = useParams();
    const [employee, setEmployee] = useState(null);

    useEffect(() => {
        const fetchEmployee = async () => {
            const docRef = doc(db, "team", employeeId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setEmployee(docSnap.data());
            } else {
                console.log("No such document!");
            }
        };

        fetchEmployee();
    }, [employeeId]);

    if (!employee) {
        return <Loader />; // Use the Loader component
    }

    return (
        <div className="team-details-container">
                <EmployeeProfile employee={employee} />
            <div className="bonus-report-section">
                <BonusReport employeeId={employeeId} />
            </div>
        </div>
    );
};

export default TeamProfile;
