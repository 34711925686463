import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { storage, db, functions } from '../firebase';  // Ensure paths are correct
import FileUpload from '../components/FileUpload';
import { mirage } from 'ldrs';
import Modal from '../components/Modal';
import { ref, uploadBytes } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import './DataInputPage.css'; // Ensure this CSS file is correctly set up


console.log("Firebase Storage loaded:", storage);
console.log("Firebase Functions loaded:", functions);

mirage.register();

const DataInputPage = () => {
    const [logs, setLogs] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [allLogsLoaded, setAllLogsLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const snapshot = await getDocs(collection(db, 'categories'));
            setCategories(snapshot.docs.map(doc => ({ id: doc.id, title: doc.data().title, color: doc.data().color })));
        };
        fetchCategories();
    }, []);

    const fetchLogs = async (initial = true) => {
        setLoading(true);
        let q;
        if (initial) {
            q = query(collection(db, "logs"), orderBy("timestamp", "desc"), limit(10));
        } else if (lastVisible) {
            q = query(collection(db, "logs"), orderBy("timestamp", "desc"), startAfter(lastVisible), limit(10));
        } else {
            setLoading(false);
            return;
        }
    
        const querySnapshot = await getDocs(q);
        const newLogs = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp.toDate().toLocaleString(),
            total: doc.data().total,
            added: doc.data().added,
            duplicates: doc.data().duplicates,
            category: doc.data().category
        }));
        
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setLogs(initial ? newLogs : logs.concat(newLogs));
        setAllLogsLoaded(querySnapshot.empty);
        setLoading(false);
    };
    
    useEffect(() => {
        fetchLogs();
    }, []);    

    useEffect(() => {
        if (uploadStatus) {
            const timer = setTimeout(() => setUploadStatus(''), 5000); // Clear status after 5 seconds
            return () => clearTimeout(timer); // Cleanup on unmount or status change
        }
    }, [uploadStatus]);

    const handleFileAccepted = file => {
        console.log('File accepted:', file.name);
        setCurrentFile(file);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setUploadStatus('Upload Canceled');
    };

    const handleCategorySelected = (category) => {
        console.log('Category selected:', category.title, 'for file:', currentFile.name);
        setShowModal(false);
        uploadFile(currentFile, category);
    };

    const uploadFile = (file, category) => {
        setUploading(true);
        const fileRef = ref(storage, `categories/${category.id}/${file.name}`);
        uploadBytes(fileRef, file).then(snapshot => {
            console.log(`${file.name} uploaded to ${category.title}`);
            triggerProcessing(file.name, category.id);
        }).catch(error => {
            console.error('Upload failed:', error);
            setUploading(false);
            setUploadStatus('Upload failed. Please try again.');
        });
    };

    const triggerProcessing = (fileName, categoryId) => {
        const processFile = httpsCallable(functions, 'processExcelUpload');
        processFile({ fileName, categoryId })
            .then(result => {
                console.log('Processing complete:', result);
                setUploading(false);
                setUploadStatus(`Upload complete. ${result.data.recordsUploaded} records uploaded, ${result.data.duplicatesRemoved} duplicates detected.`);
            }).catch(error => {
                console.error('Processing failed:', error);
                setUploading(false);
                setUploadStatus('Processing failed. Please try again.');
            });
    };

    const handleShowMoreLogs = () => {
        fetchLogs(false);
    };
    

    return (
        <div className="data-input-page">
            {uploading ? (
                <l-mirage size="60" speed="2.5" color="#1d9100"></l-mirage>
            ) : (
                <FileUpload onFileAccepted={handleFileAccepted} />
            )}
            {uploadStatus && <p className={uploadStatus === 'Upload Canceled' ? 'error-text' : 'success-text'}>{uploadStatus}</p>}
            <Modal isOpen={showModal} onClose={handleCloseModal}>
                <div className="modal-content">
                    <h2>Select Category</h2>
                    <p>{currentFile?.name}</p>
                    <div className="button-group">
                        {categories.map(category => (
                            <button key={category.id} className="button" style={{ backgroundColor: category.color }} onClick={() => handleCategorySelected(category)}>
                                {category.title}
                            </button>
                        ))}
                    </div>
                </div>
            </Modal>
            <div className="log-section">
                <h2 className='active-log'>Activity Logs</h2>
                {loading ? (
                    <p>Loading logs...</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Total</th>
                                <th>Added</th>
                                <th>Duplicates</th>
                                <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map(log => (
                                <tr key={log.id}>
                                    <td>{log.category}</td>
                                    <td>{log.total}</td>
                                    <td>{log.added}</td>
                                    <td>{log.duplicates}</td>
                                    <td>{log.timestamp}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {!allLogsLoaded && (
                    <div className='button-container'>
                    <button onClick={handleShowMoreLogs} className="show-more-button">Show More</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataInputPage;