// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBC3O1AhhQFhXmrqeMypw40AkoDzLtCQE",
  authDomain: "wastefree-4bc79.firebaseapp.com",
  projectId: "wastefree-4bc79",
  storageBucket: "wastefree-4bc79.appspot.com",
  messagingSenderId: "1001873871476",
  appId: "1:1001873871476:web:8ae81a1e8129fc3a70f721",
  measurementId: "G-5CKQJ6HYK9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { db, auth, storage, functions, app };
