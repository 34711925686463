import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import SettingsIcon from '../../assets/settings.svg';
import LogoutIcon from '../../assets/logout.svg';
import './DashboardHeader.css';

const DashboardHeader = ({ title }) => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogout = async () => {
      try {
          await signOut(auth);
          navigate('/admin/login');
      } catch (error) {
          console.error('Logout Error:', error);
      }
  };

  return (
      <header className="wf-dashboard-header">
          <div className="wf-dashboard-title">
              <h2>{title}</h2>
          </div>
          <div className="wf-dashboard-actions">
              <img src={SettingsIcon} alt="Settings" className="wf-action-icon" onClick={() => navigate('/admin/settings')} />
              <img src={LogoutIcon} alt="Logout" className="wf-action-icon" onClick={handleLogout} />
          </div>
      </header>
  );
}

export default DashboardHeader;
