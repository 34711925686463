import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { db } from '../../firebase';
import NotFound from '../../components/NotFound';
import Loader from '../../components/Loader'; // Import the Loader component
import './CommunitySite.css';

const CommunityPublic = () => {
  const { propertyName } = useParams();
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [serviceStatus, setServiceStatus] = useState('Not Serviced');
  const [lastChecked, setLastChecked] = useState(null);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    unitNumber: ''
  });
  const [timestamps, setTimestamps] = useState([]);
  const [isOnSite, setIsOnSite] = useState(false);

  const learnMoreRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const cachedPropertyData = localStorage.getItem(`propertyData-${propertyName}`);
    const cachedTimestamps = localStorage.getItem(`timestamps-${propertyName}`);

    if (cachedPropertyData) {
      setPropertyData(JSON.parse(cachedPropertyData));
      setLoading(false);
    }

    if (cachedTimestamps) {
      const { timestamps, isOnSite } = JSON.parse(cachedTimestamps);
      setTimestamps(timestamps);
      setIsOnSite(isOnSite);
    } else {
      fetchOnSiteStatus(propertyName);
    }

    if (!cachedPropertyData) {
      fetchPropertyData();
    }
  }, [propertyName]);

  const fetchServiceStatus = async () => {
    setCheckingStatus(true);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const clockInOutCollection = collection(db, 'clockInOuts');
    const q = query(
      clockInOutCollection,
      where('Property Name', '==', propertyName.toLowerCase()),
      where('Clockin', '>=', today),
      where('Clockin', '<', tomorrow)
    );

    try {
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setServiceStatus('Not Serviced');
        setTimestamps([]);
      } else {
        const clockIns = querySnapshot.docs.map(doc => ({
          clockin: doc.data().Clockin.toDate(),
          clockout: doc.data().Clockout ? doc.data().Clockout.toDate() : null
        }));

        const currentTime = new Date();
        const activeService = clockIns.find(ci => ci.clockin <= currentTime && (!ci.clockout || ci.clockout > currentTime));

        if (activeService) {
          setServiceStatus('Currently Being Serviced');
        } else if (clockIns.some(ci => ci.clockout)) {
          setServiceStatus('Already Served');
        } else {
          setServiceStatus('Not Serviced');
        }
        setTimestamps(clockIns);
      }
      setLastChecked(new Date());
    } catch (error) {
      console.error("Error fetching service status:", error);
      setServiceStatus('Error checking status');
      setTimestamps([]);
    }
    setCheckingStatus(false);
  };

  // Call this function when the component mounts
  useEffect(() => {
    fetchServiceStatus();
  }, [propertyName]);

  const fetchPropertyData = async () => {
    const docRef = doc(db, 'communities', propertyName.toLowerCase());
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setPropertyData(docSnap.data());
      localStorage.setItem(`propertyData-${propertyName}`, JSON.stringify(docSnap.data()));
    } else {
      console.log(`No property found for ID: ${propertyName}`);
    }
    setLoading(false);
  };

  const fetchOnSiteStatus = async (propertyId) => {
    setCheckingStatus(true);
    const clockInOutCollection = collection(db, 'clockInOuts');
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const q = query(
      clockInOutCollection,
      where('Property Name', '==', propertyId),
      where('Clockin', '>=', today)
    );

    const querySnapshot = await getDocs(q);
    const timestamps = querySnapshot.docs.map(doc => ({
      clockin: doc.data().Clockin,
      clockout: doc.data().Clockout
    }));

    setTimestamps(timestamps);
    setIsOnSite(timestamps.some(ts => !ts.clockout));
    localStorage.setItem(`timestamps-${propertyName}`, JSON.stringify({ timestamps, isOnSite }));
    setCheckingStatus(false);
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp instanceof Date) {
      return timestamp.toLocaleTimeString();
    } else if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleTimeString();
    } else {
      return 'Invalid Date';
    }
  };

  const handleLearnMoreClick = () => {
    learnMoreRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleContactClick = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message) {
      alert('Please fill out all fields.');
      return;
    }

    try {
      const contactRef = collection(db, `communities/${propertyName.toLowerCase()}/contact`);
      await addDoc(contactRef, {
        ...formData,
        timestamp: new Date()
      });
      alert('Your message has been sent successfully!');
      setFormData({ name: '', email: '', message: '', unitNumber: '' });
    } catch (error) {
      console.error('Error sending message: ', error);
      alert('There was an error sending your message. Please try again.');
    }
  };

  if (loading) {
    return <Loader />; // Use the Loader component
  }

  if (!propertyData) {
    return <NotFound />;
  }

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "ApartmentComplex",
    "name": propertyData.propertyName,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": propertyData.address,
      "addressLocality": "City",
      "addressRegion": "State",
      "postalCode": "ZIP"
    },
    "numberOfUnits": propertyData.unitNumber,
  };

  return (
    <HelmetProvider>
      <div className="wf-community__container">
        <Helmet>
          <title>{propertyData.propertyName} - Community Details</title>
          <meta name="description" content={`Learn more about ${propertyData.propertyName}, located at ${propertyData.address}.`} />
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
        <header className="wf-community__hero">
          <div className="wf-community__hero-content">
            <h1 className="wf-community__hero-title">{propertyData.propertyName} Valet Service Hub</h1>
            <p className="wf-community__hero-subtitle">what we do in the dark, shines in the light</p>
            <div className="wf-community__button-group">
              <button className="wf-community__button wf-community__button--primary" onClick={handleLearnMoreClick}>Learn More</button>
              <button className="wf-community__button wf-community__button--secondary" onClick={handleContactClick}>Contact Us</button>
            </div>
          </div>
        </header>

        <div className="wf-community__status-bar">
          <div className="wf-community__status-content">
            <div className="wf-community__status-label">Today's Service Status:</div>
            <div className={`wf-community__status-indicator ${serviceStatus.toLowerCase().replace(' ', '-')}`}>
              {serviceStatus}
            </div>
            <button 
              className="wf-community__check-status-button" 
              onClick={fetchServiceStatus} 
              disabled={checkingStatus}
            >
              {checkingStatus ? 'Checking...' : 'Check Status'}
            </button>
          </div>
          <div className="wf-community__status-details">
            {lastChecked && (
              <div className="wf-community__last-checked">
                Last checked: {lastChecked.toLocaleTimeString()}
              </div>
            )}
            {timestamps.length > 0 && timestamps[0].clockin && (
              <div className="wf-community__service-time">
                {timestamps[0].clockin && !timestamps[0].clockout && `Collection specialist arrived @ ${formatTimestamp(timestamps[0].clockin)}`}
                {timestamps[0].clockout && `Collection specialist left @ ${formatTimestamp(timestamps[0].clockout)}`}
              </div>
            )}
          </div>
        </div>

        <main className="wf-community__main">
          <section ref={learnMoreRef} className="wf-community__section introduction">
            <h2 className="wf-community__section-title">Welcome to WasteFree Valet Trash Services</h2>
            <p className="wf-community__section-content">At {propertyData.propertyName}, we're proud to partner with WasteFree to provide top-tier valet trash and recycling services. Our commitment to cleanliness, convenience, and environmental responsibility sets us apart, ensuring a superior living experience for all our residents.</p>
          </section>

          <section className="wf-community__section our-services">
            <h2 className="wf-community__section-title">Our Comprehensive Services</h2>
            <p className="wf-community__section-content">WasteFree offers a range of services designed to keep your living space clean and clutter-free:</p>
            <div className="wf-community__service-grid">
              <div className="wf-community__service-card">
                <i className="fas fa-trash wf-community__service-icon"></i>
                <h3 className="wf-community__service-title">Valet Trash Collection</h3>
                <p>Experience the convenience of doorstep trash collection. Our reliable team ensures timely pickup, maintaining a spotless property environment.</p>
              </div>
              <div className="wf-community__service-card">
                <i className="fas fa-recycle wf-community__service-icon"></i>
                <h3 className="wf-community__service-title">Recycling Services</h3>
                <p>Contribute to a greener future with our easy valet recycling. We make sustainable living simple and accessible for all residents.</p>
              </div>
              <div className="wf-community__service-card">
                <i className="fas fa-couch wf-community__service-icon"></i>
                <h3 className="wf-community__service-title">Bulk Trash Removal</h3>
                <p>From old furniture to large appliances, our bulk trash removal service keeps your community clean and clutter-free.</p>
              </div>
            </div>
          </section>

          <section className="wf-community__section benefits">
            <h2 className="wf-community__section-title">Why Choose WasteFree?</h2>
            <ul className="wf-community__list">
              <li className="wf-community__list-item">Convenience: Say goodbye to trips to the dumpster. We collect directly from your doorstep.</li>
              <li className="wf-community__list-item">Cleanliness: Maintain a tidy living environment with regular, reliable waste collection.</li>
              <li className="wf-community__list-item">Eco-friendly: Our recycling services promote sustainability within your community.</li>
              <li className="wf-community__list-item">Property Value: Valet trash services enhance the appeal and value of your property.</li>
              <li className="wf-community__list-item">Time-saving: Focus on what matters most to you while we handle the waste management.</li>
            </ul>
          </section>

          <section id="contact" ref={contactRef} className="wf-community__section contact-us">
            <h2 className="wf-community__section-title">Get in Touch</h2>
            <p className="wf-community__section-content">Interested in our services or joining our team? We'd love to hear from you! Contact us today for more information or to schedule a consultation.</p>
            <div className="wf-community__contact-form">
              <form onSubmit={handleSubmit}>
                <div className="wf-community__form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="wf-community__form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="wf-community__form-group">
                  <label htmlFor="unitNumber">Unit Number (if applicable)</label>
                  <input
                    type="text"
                    id="unitNumber"
                    name="unitNumber"
                    value={formData.unitNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="wf-community__form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="wf-community__button wf-community__button--primary">Send Message</button>
              </form>
            </div>
            <div className="wf-community__contact-info">
              <p>Phone: (855) 877-5418</p>
              <p>Email: info@wastefree.com</p>
              <p>Address: {propertyData.address}</p>
            </div>
          </section>
        </main>

        <footer className="wf-community__footer">
          <div className="wf-community__footer-content">
            <div className="wf-community__footer-section">
              <h3 className="wf-community__footer-title">Quick Links</h3>
              <ul className="wf-community__footer-list">
                <li className="wf-community__footer-item">
                  <a href="https://www.wastefree.com/pricing/packages" className="wf-community__footer-link">Services</a>
                </li>
                <li className="wf-community__footer-item">
                  <a href="https://www.wastefree.com/how-it-works/resident-managers" className="wf-community__footer-link">Property Manager</a>
                </li>
                <li className="wf-community__footer-item">
                  <a href="https://www.wastefree.com/pricing/testimonials" className="wf-community__footer-link">Testimonials</a>
                </li>
                <li className="wf-community__footer-item">
                  <a href="https://www.wastefree.com/about" className="wf-community__footer-link">About Us</a>
                </li>
              </ul>
            </div>
            <div className="wf-community__footer-section">
              <h3 className="wf-community__footer-title">Contact Info</h3>
              <p>Phone: (855) 877-5418</p>
              <p>Email: info@wastefree.com</p>
            </div>
            <div className="wf-community__footer-section">
              <h3 className="wf-community__footer-title">Follow Us</h3>
              <div className="wf-community__social-icons">
                <a href="#" className="wf-community__social-icon"><i className="fab fa-facebook"></i></a>
                <a href="#" className="wf-community__social-icon"><i className="fab fa-twitter"></i></a>
                <a href="#" className="wf-community__social-icon"><i className="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>
          <div className="wf-community__copyright">
            &copy; 2024 WasteFree Valet Trash Services. All rights reserved.
          </div>
        </footer>
      </div>
    </HelmetProvider>
  );
};

export default CommunityPublic;
